import EventEmitterMicro from './event-emitter-micro'

var startTime = new Date().getTime()

export class Clock extends EventEmitterMicro {
  constructor () {
    super()
    this.lastFrameTime = null
    this._animationFrame = null
    this._active = false
    this._startTime = null
    this._boundOnAnimationFrame = this._onAnimationFrame.bind(this)
    this._getTime = Date.now || function () {
      return new Date().getTime()
    }
  }

  start () {
    if (this._active) {
      return
    }
    this._tick()
  }

  stop () {
    if (this._active) {
      window.cancelAnimationFrame(this._animationFrame)
    }
    this._animationFrame = null
    this.lastFrameTime = null
    this._active = false
  }

  destroy () {
    this.stop()
    this.off()
    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        this[prop] = null
      }
    }
  }

  isRunning () {
    return this._active
  }

  _tick () {
    if (!this._active) {
      this._active = true
    }
    this._animationFrame = window.requestAnimationFrame(this._boundOnAnimationFrame)
  }

  _onAnimationFrame (time) {
    let delta = 0
    let timeNow = this._getTime()
    if (this.lastFrameTime === null) {
      this.lastFrameTime = timeNow - startTime
    } else {
      delta = time - this.lastFrameTime
    }
    let fps = 0
    if (delta !== 0) {
      fps = 1000 / delta
    }
    let naturalFps = fps
    let event = {time, delta, fps, naturalFps, timeNow}
    this.trigger('update', event)
    this.trigger('draw', event)
    this._animationFrame = null
    this.lastFrameTime = time
    if (this._active !== false) {
      this._tick()
    } else {
      this.lastFrameTime = null
    }
  }
}

export class ThrottledClock extends EventEmitterMicro {
  constructor (fps, options) {
    super()
    options = options || {}
    this._fps = fps || null
    this._clock = options.clock || sharedClockInstance
    this._lastThrottledTime = null
    this._clockEvent = null
    this._boundOnClockDraw = this._onClockDraw.bind(this)
    this._boundOnClockUpdate = this._onClockUpdate.bind(this)
    this._clock.on('update', this._boundOnClockUpdate)
  }

  setFps (fps) {
    this._fps = fps
    return this
  }

  getFps () {
    return this._fps
  }

  start () {
    this._clock.start()
    return this
  }

  stop () {
    this._clock.stop()
    return this
  }

  isRunning () {
    return this._clock.isRunning()
  }

  destroy () {
    this._clock.off('update', this._boundOnClockUpdate)
    this._clock.destroy.call(this)
  }

  _onClockUpdate (event) {
    if (this._lastThrottledTime === null) {
      this._lastThrottledTime = this._clock.lastFrameTime
    }
    let delta = event.time - this._lastThrottledTime
    if (!this._fps) {
      throw new TypeError('FPS is not defined.')
    }
    if (delta < (1000 / this._fps)) {
      return
    }
    this._clockEvent = event
    this._clockEvent.delta = delta
    this._clockEvent.fps = 1000 / delta
    this._lastThrottledTime = this._clockEvent.time
    this._clock.once('draw', this._boundOnClockDraw)
    this.trigger('update', this._clockEvent)
  }

  _onClockDraw () {
    this.trigger('draw', this._clockEvent)
  }
}

const sharedClockInstance = new Clock()

export default sharedClockInstance
