export function lerp (ratio, start, end) {
  return start + (end - start) * ratio
}

export function map (val, min1, max1, min2, max2) {
  return this.lerp(this.norm(val, min1, max1), min2, max2)
}

export function norm (val, min, max) {
  return (val - min) / (max - min)
}

export function clamp (val, min, max) {
  return Math.max(min, Math.min(max, val))
}

export function randFloat (min, max) {
  return (Math.random() * (max - min)) + min
}

export function randInt (min, max) {
  return Math.floor((Math.random() * (max - min)) + min)
}
