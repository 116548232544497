// Entry point for the main script
__webpack_public_path__ = CMTConfig.path + '/' // eslint-disable-line
window.HELP_IMPROVE_VIDEOJS = false
window.VIDEOJS_NO_BASE_THEME = true
window.VIDEOJS_NO_DYNAMIC_STYLE = true
require('./polyfills/custom-event')
require('./polyfills/matches')
// Instantiate framework
require('./instance')
// Run regular scripts
require('./go-to-top')
require('./section-menu')
require('./skip-link-focus-fix')
// Run page builder scripts
require('pb/main')
