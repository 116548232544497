import viewportEmitter from './viewport-emitter'
import MotionEmitter from './motion-emitter'

// import ResizeObserver from 'resize-observer-polyfill'

export class ScrollMotionEmitter extends MotionEmitter {
  constructor (options = {}) {
    super(options)

    this.smooth = this.options.smooth || false

    if (!this.options.overrideScroll) {
      this._bindScrollEvents()
    }
  }

  updateValue (a) {
    if (this.smooth) {
      return super.updateValue(a)
    }
    if (this._currentValue === this._targetValue) {
      this._shouldEmitChange = false
      return
    }
    this._currentValue = this._targetValue
    this._shouldEmitChange = true
  }

  handleScroll (scrollPosition) {
    if (typeof scrollPosition !== 'number') {
      scrollPosition = viewportEmitter.scrollY()
    }
    var progress
    if (scrollPosition < this.min) {
      progress = this.min
    } else {
      if (scrollPosition > this.max) {
        progress = this.max
      } else {
        progress = scrollPosition
      }
    }
    progress = (progress - this.min) / (this.max - this.min)
    this.setProgress(progress)
  }

  destroy () {
    if (this._boundHandleScroll) {
      viewportEmitter.off('scroll', this._boundHandleScroll)
    }
    return super.destroy()
  }

  _bindScrollEvents () {
    this._boundHandleScroll = this.handleScroll.bind(this)
    viewportEmitter.on('scroll', this._boundHandleScroll)
  }
}

export class ElementScrollMotionEmitter extends ScrollMotionEmitter {
  constructor (element, options = {}) {
    super(options)
    if (!(element instanceof window.HTMLElement)) {
      return
    }
    this.el = element
    this.options = options
    if (this.options.offsetTop) {
      this.offsetTop = this.options.offsetTop
    }
    if (this.options.offsetBottom) {
      this.offsetBottom = this.options.offsetBottom
    }
    this.setEmitterBounds()
    if (!this.options.overrideResize) {
      this._bindResizeEvents()
    }
  }

  setEmitterBounds () {
    this._elementBounds = this.el.getBoundingClientRect()
    var scrollPosition = viewportEmitter.scrollY()
    var topScroll = this._elementBounds.top + scrollPosition
    var bottomScroll = this._elementBounds.bottom + scrollPosition
    var offsetTop = this.offsetTop || 0
    var offsetBottom = this.offsetBottom || 0
    if (typeof this.offsetTop === 'function') {
      offsetTop = this.offsetTop()
    }
    if (typeof this.offsetBottom === 'function') {
      offsetBottom = this.offsetBottom()
    }
    this.min = this.options.min = topScroll + offsetTop
    this.max = this.options.max = bottomScroll + offsetBottom
  }

  destroy () {
    if (this._boundHandleResize) {
      viewportEmitter.off('resize', this._boundHandleResize)
      viewportEmitter.off('orientationchange', this._boundHandleResize)
    }
    // this.ro.disconnect()
    super.destroy()
  }

  _bindResizeEvents () {
    this._boundHandleResize = this.handleResize.bind(this)
    viewportEmitter.on('resize', this._boundHandleResize)
    viewportEmitter.on('orientationchange', this._boundHandleResize)

    /*
    this.ro = new ResizeObserver((entries, observer) => {
      this._boundHandleResize()
    })
    this.ro.observe(this.el)
    */
  }

  _handleClockUpdate (a) {
    if (this._shouldUpdateOnResize) {
      this.setEmitterBounds()
      this.handleScroll()
      this._shouldUpdateOnResize = false
    }
    super._handleClockUpdate(a)
  }

  handleResize () {
    this._shouldUpdateOnResize = true
    this.handleScroll()
  }
}
