/* global CMTConfig */
import EventEmitterMicro from 'lib/event-emitter-micro'

const choicesPerHour = 4
const l18n = CMTConfig.l18n

const yearOffset = new Date().getFullYear()

function now () {
  return new Date()
}

function getDaysInMonth (year, month) {
  return new Date(Date.UTC(year, month + 1, 0)).getDate()
}

export default class DateInput extends EventEmitterMicro {
  constructor (yearInput, monthInput, dayInput, timeInput) {
    super()
    this.yearInput = yearInput
    this.monthInput = monthInput
    this.dayInput = dayInput
    this.timeInput = timeInput

    // We remove the required field. It causes issues with firefox, and the fields are always set anyway
    yearInput.required = false
    monthInput.required = false
    dayInput.required = false

    this.years = this.yearInput.children.length

    this.yearInput.addEventListener('change', () => {
      this.setFirstAvailable(false) // Do not modify year
      this.handleChange()
    })
    this.monthInput.addEventListener('change', () => {
      this.setFirstAvailable(false, false) // Do not modify year & month
      this.handleChange()
    })
    this.dayInput.addEventListener('change', () => {
      this.setFirstAvailable(false, false, false) // Do not modify year, month & day
      this.handleChange()
    })
    if (this.timeInput) {
      this.timeInput.required = false
      this.timeInput.addEventListener('change', () => {
        this.trigger('change')
      })
    }
  }

  initializeAvailability (openingHours, floor, ceiling) {
    floor += l18n.timezone.offs * 1000 * 60 * 60 // Add timezone offset
    ceiling += l18n.timezone.offs * 1000 * 60 * 60 // Add timezone offset
    const availability = {
      years: {}
    }
    let dateNow = now()

    let dateNowWithoutTime = now()
    dateNowWithoutTime.setUTCHours(0, 0, 0, 0)
    dateNowWithoutTime = dateNowWithoutTime.getTime()

    for (let year = yearOffset; year < yearOffset + this.years; year++) {
      let yearData = []
      for (let month = 0; month < 12; month++) {
        let daysInMonth = getDaysInMonth(year, month)
        let monthData = []
        for (let day = 0; day < 31; day++) {
          if (day >= daysInMonth) {
            monthData[day] = false
            continue
          }
          let date = new Date(Date.UTC(year, month, day + 1))
          let weekDay = (date.getUTCDay() || 7) - 1
          date = date.getTime()

          let dayData = []
          for (let spanConfig of openingHours[weekDay]) {
            let span = spanConfig.slice(0)

            if (date < dateNowWithoutTime) {
              // Date has passed, it's not available
              continue
            } else if (date >= ceiling) {
              // Date is past the ceiling, it's not available
              continue
            } else if (span[0] === 0 && span[1] === 0) {
              // The timespan is 00:00-00:00, it's always closed
              continue
            } else if (date === dateNowWithoutTime) {
              // It's the current date (Is this necessary with the new time floor code? probably not)
              if (dateNow.getUTCHours() >= span[1]) {
                // It's past the closing time
                continue
              }
            }

            // Date is available, check time floor

            // Calculate how many hours into the day you must be
            let time = (floor - date) / 1000 / 60 / 60

            // And use the greatest value
            span[0] = Math.max(span[0], time)

            // Make sure that the time aligns with choices per hour
            span[0] = Math.ceil(span[0] * choicesPerHour) / choicesPerHour

            if (span[0] > span[1]) {
              // It opens after it closes
              continue
            }

            dayData.push(span)
          }
          if (dayData.length) {
            monthData[day] = dayData
          } else {
            monthData[day] = false
          }
        }
        if (!monthData.some((element) => typeof element === 'object')) {
          // All days are closed
          monthData = false
        }
        yearData[month] = monthData
      }
      if (!yearData.some((element) => element !== false)) {
        // All months are closed
        yearData = false
      }
      availability.years[year] = yearData
    }
    this.availability = availability
  }

  handleChange () {
    this.refreshAvailability()
    this.refreshWeekdays()
    this.trigger('change')
  }

  setFirstAvailable (setYear = true, setMonth = true, setDay = true, setTime = true) {
    let currentYear = this.getYear()
    let currentMonth = this.getMonth()
    let currentDay = this.getDay()

    // Check Year
    let availability = this.availability.years
    if (setYear && availability[currentYear] === false) {
      // Current year is unavailable
      let newYear = currentYear
      while (++newYear) {
        if (!(newYear in availability)) {
          // We're past the last year, wrap around to the first one
          newYear = yearOffset
        }
        if (availability[newYear] !== false) {
          // The year is available
          break
        }
        if (newYear === currentYear) {
          // We've gone one cycle without finding an available year
          console.error('DateInput: Could not find an available year')
          return
        }
      }
      this.setYear(newYear)
      currentYear = newYear
    }

    // Check Month
    let yearData = availability[currentYear]
    if (setMonth && yearData[currentMonth] === false) {
      // Current month is unavailable
      let newMonth = currentMonth
      let step = 1
      while ((newMonth += step)) {
        if (newMonth > 11) {
          // We're past the last month, try the opposite direction
          newMonth = currentMonth - 1
          step = -1
        }
        if (newMonth < 0) {
          // We've tried all months without finding an available one
          console.error('DateInput: Could not find an available month')
          return
        }
        if (yearData[newMonth] !== false) {
          // The month is available
          break
        }
      }
      this.setMonth(newMonth)
      currentMonth = newMonth
    }

    // Check Day
    let monthData = yearData[currentMonth]
    if (setDay && monthData[currentDay] === false) {
      // Current day is unavailable
      let newDay = currentDay
      let step = 1
      while ((newDay += step)) {
        if (newDay >= monthData.length) {
          // We're past the last day, try the opposite direction
          newDay = currentDay - 1
          step = -1
        }
        if (newDay < 0) {
          // We've tried all days without finding an available one
          console.error('DateInput: Could not find an available day')
          return
        }
        if (monthData[newDay] !== false) {
          // The day is available
          break
        }
      }
      this.setDay(newDay)
      currentDay = newDay
    }

    // Check Time
    if (this.timeInput && setTime) {
      let currentTime = this.getTime()
      // TODO: Add support for several spans
      let span = monthData[currentDay][0]
      let start = span[0] * choicesPerHour
      let end = span[1] * choicesPerHour
      if (currentTime < start || currentTime > end) {
        // Current time is unavailable
        let newTime = currentTime
        let step = 1
        while ((newTime += step)) {
          if (newTime >= 24 * choicesPerHour) {
            // We're past the last time, try the opposite direction
            newTime = currentTime - 1
            step = -1
          }
          if (newTime < 0) {
            // We've tried all times without finding an available one
            console.error('DateInput: Could not find an available time')
            return
          }
          if (newTime >= start && newTime <= end) {
            // The time is available
            break
          }
        }
        this.setTime(newTime)
        currentTime = newTime
      }
    }
  }
  refreshAvailability () {
    const currentYear = this.getYear()
    const currentMonth = this.getMonth()
    const currentDay = this.getDay()

    // Update year inputs
    let availability = this.availability.years
    for (let i = 0; i < this.years; i++) {
      this.yearInput.children[i].disabled = availability[yearOffset + i] === false
    }

    // Update month inputs
    const currentYearIsUnavailable = availability[currentYear] === false
    for (let i = 0; i < 12; i++) {
      this.monthInput.children[i].disabled = currentYearIsUnavailable || (availability[currentYear][i] === false)
    }

    // Update day inputs
    const currentMonthIsUnavailable = currentYearIsUnavailable || availability[currentYear][currentMonth] === false
    const daysInMonth = getDaysInMonth(currentYear, currentMonth)
    if (daysInMonth !== this.dayInput.length) {
      if (this.dayInput.length < daysInMonth) {
        // There are missing dates, add them
        for (let i = this.dayInput.length; i < daysInMonth; i++) {
          let element = document.createElement('option')
          element.value = i
          this.dayInput.appendChild(element)
        }
      } else if (this.dayInput.length > daysInMonth) {
        // There are unavailable dates present, remove them
        for (let i = this.dayInput.length; i > daysInMonth; i--) {
          this.dayInput.removeChild(this.dayInput.children[i - 1])
        }
      }
    }
    for (let i = 0, l = this.dayInput.length; i < l; i++) {
      let element = this.dayInput[i]
      let disabled = currentMonthIsUnavailable || (availability[currentYear][currentMonth][i] === false)
      element.disabled = disabled
    }

    // Update time inputs
    if (this.timeInput) {
      // TODO: Add support for several spans
      let span = availability[currentYear][currentMonth][currentDay][0]
      const currentDayIsUnavailable = currentMonthIsUnavailable || span === false
      if (currentDayIsUnavailable) {
        // data is unavailable, clear all time inputs; this should never happen
        for (let i = 0, l = this.timeInput.children.length; i < l; i++) {
          this.timeInput.removeChild(this.timeInput.children[0])
        }
      } else {
        let start = span[0] * choicesPerHour
        let end = span[1] * choicesPerHour
        // Check if number of choices has changed, OR the first choice is different
        // NOTE: When multiple spans are allowed, this check will have to change
        if (end - start !== this.timeInput.length - 1 || parseInt(this.timeInput[0].value) !== start) {
          // If it is, clear all time options
          for (let i = 0, l = this.timeInput.children.length; i < l; i++) {
            this.timeInput.removeChild(this.timeInput.children[0])
          }
          // And add time all time options that should be present
          for (let i = start; i <= end; i++) {
            let element = document.createElement('option')
            element.value = i
            let minutes = i * 60 / choicesPerHour
            let m = Math.floor(minutes % 60)
            let h = Math.floor(minutes / 60)

            let stamp = ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2)
            element.innerHTML = stamp + ' ' + l18n.timezone.abbr
            this.timeInput.appendChild(element)
          }
        }
      }
    }
  }

  refreshWeekdays () {
    const currentYear = this.getYear()
    const currentMonth = this.getMonth()
    for (let i = 0; i < 31; i++) {
      let element = this.dayInput.children[i]
      if (element) {
        let weekday = (new Date(Date.UTC(currentYear, currentMonth, i + 1)).getDay() || 7) - 1
        element.innerHTML = `${i + 1} (${l18n.weekday[weekday]})`
      }
    }
  }

  setYear (year) {
    this.yearInput.value = year - yearOffset
    return year
  }

  getYear () {
    return parseInt(this.yearInput.value) + yearOffset
  }

  setMonth (month) {
    this.monthInput.value = month
    return month
  }

  getMonth () {
    return parseInt(this.monthInput.value)
  }

  setDay (day) {
    this.dayInput.value = day
    return day
  }

  getDay () {
    return parseInt(this.dayInput.value)
  }

  setTime (time) {
    if (this.timeInput) {
      this.timeInput.value = time
    }
    return time
  }

  getTime () {
    if (!this.timeInput) {
      return 0
    }
    return parseInt(this.timeInput.value)
  }

  getDate () {
    let time = this.getTime() / choicesPerHour
    let hours = Math.floor(time)
    let minutes = (time - hours) * 60
    return new Date(Date.UTC(this.getYear(), this.getMonth(), this.getDay() + 1, hours, minutes))
  }
}
