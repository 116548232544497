// arguments:
// t = current time
// b = start value
// c = change in value
// d = duration

// simple linear tweening - no easing, no acceleration
export function linearTween (t, b, c, d) {
  return c * t / d + b
}

// quadratic easing in - accelerating from zero velocity
export function easeInQuad (t, b, c, d) {
  t /= d
  return c * t * t + b
}

// quadratic easing out - decelerating to zero velocity
export function easeOutQuad (t, b, c, d) {
  t /= d
  return -c * t * (t - 2) + b
}

// quadratic easing in/out - acceleration until halfway, then deceleration
export function easeInOutQuad (t, b, c, d) {
  t /= d / 2
  if (t < 1) return c / 2 * t * t + b
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}

// cubic easing in - accelerating from zero velocity
export function easeInCubic (t, b, c, d) {
  t /= d
  return c * t * t * t + b
}

// cubic easing out - decelerating to zero velocity
export function easeOutCubic (t, b, c, d) {
  t /= d
  t--
  return c * (t * t * t + 1) + b
}

// cubic easing in/out - acceleration until halfway, then deceleration
export function easeInOutCubic (t, b, c, d) {
  t /= d / 2
  if (t < 1) return c / 2 * t * t * t + b
  t -= 2
  return c / 2 * (t * t * t + 2) + b
}

// quartic easing in - accelerating from zero velocity
export function easeInQuart (t, b, c, d) {
  t /= d
  return c * t * t * t * t + b
}

// quartic easing out - decelerating to zero velocity
export function easeOutQuart (t, b, c, d) {
  t /= d
  t--
  return -c * (t * t * t * t - 1) + b
}

// quartic easing in/out - acceleration until halfway, then deceleration
export function easeInOutQuart (t, b, c, d) {
  t /= d / 2
  if (t < 1) return c / 2 * t * t * t * t + b
  t -= 2
  return -c / 2 * (t * t * t * t - 2) + b
}

// quintic easing in - accelerating from zero velocity
export function easeInQuint (t, b, c, d) {
  t /= d
  return c * t * t * t * t * t + b
}

// quintic easing out - decelerating to zero velocity
export function easeOutQuint (t, b, c, d) {
  t /= d
  t--
  return c * (t * t * t * t * t + 1) + b
}

// quintic easing in/out - acceleration until halfway, then deceleration
export function easeInOutQuint (t, b, c, d) {
  t /= d / 2
  if (t < 1) return c / 2 * t * t * t * t * t + b
  t -= 2
  return c / 2 * (t * t * t * t * t + 2) + b
}

// sinusoidal easing in - accelerating from zero velocity
export function easeInSine (t, b, c, d) {
  return -c * Math.cos(t / d * (Math.PI / 2)) + c + b
}

// sinusoidal easing out - decelerating to zero velocity
export function easeOutSine (t, b, c, d) {
  return c * Math.sin(t / d * (Math.PI / 2)) + b
}

// sinusoidal easing in/out - accelerating until halfway, then decelerating
export function easeInOutSine (t, b, c, d) {
  return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b
}

// exponential easing in - accelerating from zero velocity
export function easeInExpo (t, b, c, d) {
  return c * Math.pow(2, 10 * (t / d - 1)) + b
}

// exponential easing out - decelerating to zero velocity
export function easeOutExpo (t, b, c, d) {
  return c * (-Math.pow(2, -10 * t / d) + 1) + b
}

// exponential easing in/out - accelerating until halfway, then decelerating
export function easeInOutExpo (t, b, c, d) {
  t /= d / 2
  if (t < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b
  t--
  return c / 2 * (-Math.pow(2, -10 * t) + 2) + b
}

// circular easing in - accelerating from zero velocity
export function easeInCirc (t, b, c, d) {
  t /= d
  return -c * (Math.sqrt(1 - t * t) - 1) + b
}

// circular easing out - decelerating to zero velocity
export function easeOutCirc (t, b, c, d) {
  t /= d
  t--
  return c * Math.sqrt(1 - t * t) + b
}

// circular easing in/out - acceleration until halfway, then deceleration
export function easeInOutCirc (t, b, c, d) {
  t /= d / 2
  if (t < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b
  t -= 2
  return c / 2 * (Math.sqrt(1 - t * t) + 1) + b
}
