let gmapsLoaded = false

function GoogleMapLabel (options) {
  // Initialization
  this.setValues(options)

  // Label specific
  let span = this.span_ = document.createElement('span')
  span.className = 'google-map-label'

  let div = this.div_ = document.createElement('div')
  div.appendChild(span)
  div.style.cssText = 'position: absolute; display: none'
}

window.addEventListener('cmt-google-maps-initialize', function () {
  let google = window.google
  // Define the overlay, derived from google.maps.OverlayView
  GoogleMapLabel.prototype = new google.maps.OverlayView()

  // Implement onAdd
  GoogleMapLabel.prototype.onAdd = function () {
    let pane = this.getPanes().overlayLayer
    pane.appendChild(this.div_)

    // Ensures the label is redrawn if the text or position is changed.
    this.listeners_ = [
      google.maps.event.addListener(this, 'position_changed', () => { this.draw() }),
      google.maps.event.addListener(this, 'text_changed', () => { this.draw() })
    ]
  }

  // Implement onRemove
  GoogleMapLabel.prototype.onRemove = function () {
    this.div_.parentNode.removeChild(this.div_)

    // Label is removed from the map, stop updating its position/text.
    for (let i = 0, I = this.listeners_.length; i < I; ++i) {
      google.maps.event.removeListener(this.listeners_[i])
    }
  }

  // Implement draw
  GoogleMapLabel.prototype.draw = function () {
    let projection = this.getProjection()
    let position = projection.fromLatLngToDivPixel(this.get('position'))

    let div = this.div_
    div.style.left = position.x + 'px'
    div.style.top = position.y + 'px'
    div.style.display = 'block'

    this.span_.innerHTML = this.get('text').toString()
  }

  // Dispatch Custom Event
  gmapsLoaded = true
  initializeMaps()
})

let _remSize = false
function remSize () {
  if (!_remSize) {
    _remSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize)
  }
  return _remSize
}

let initializeMap = function (wrap) {
  const google = window.google
  wrap.dataset.initialized = true

  let zoom = parseFloat(wrap.dataset.zoom)
  let baseHeight = parseFloat(wrap.dataset.baseHeight) * remSize()
  // Take scale into account to make roughly the same part of the map visible
  zoom = Math.max(1, Math.floor(zoom - Math.sqrt(baseHeight / wrap.clientHeight) + 1))
  let mapCanvas = wrap.querySelector('.google-map-canvas')
  let mapOptions = {
    scaleControl: false,
    center: new google.maps.LatLng(
      wrap.dataset.lat,
      wrap.dataset.lng
    ),
    zoom: zoom,
    minZoom: 1,
    gestureHandling: 'cooperative',
    mapTypeControl: false,
    streetViewControl: false,
    styles: window.CMTGMapStyles || []
  }

  let map = new google.maps.Map(mapCanvas, mapOptions)

  let markers = JSON.parse(wrap.dataset.markers)
  if (!markers.length) {
    return
  }

  let activeInfoWindow
  for (let settings of markers) {
    let title = settings.title || ''
    let content = settings.content || ''
    let icon = settings.icon || ''
    let url = settings.url || ''

    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        settings.position.lat,
        settings.position.lng
      ),
      map,
      title,
      icon
    })

    let contentString = `
      <div class="google-map-box-wrap">
        <p class="google-map-box-heading">${title}</p>
        <div class="google-map-box-content">
          <p>${content}</p>
        </div>
      </div>
    `

    let infowindow = new google.maps.InfoWindow({
      content: contentString
    })

    marker.addListener('click', function () {
      if (activeInfoWindow) {
        if (activeInfoWindow === infowindow) {
          if (url) {
            window.location.href = url
          }
          return
        }
        activeInfoWindow.close()
      }
      activeInfoWindow = infowindow
      if (title || content) {
        infowindow.open(map, marker)
      }
    })

    infowindow.addListener('closeclick', function () {
      if (activeInfoWindow === infowindow) {
        activeInfoWindow = null
      }
    })

    // Add label if title is set
    if (title) {
      let label = new GoogleMapLabel({
        map: map
      })
      label.bindTo('position', marker, 'position')
      label.bindTo('text', marker, 'title')
    }
  }
}

function initializeMaps (layout) {
  if (!layout) {
    layout = document
  }
  let maps = layout.querySelectorAll('.google-map[data-initialized="false"]')
  for (let i = 0; i < maps.length; i++) {
    initializeMap(maps[i])
  }
}

window.addEventListener('cmpb-layout-loaded', function (event) {
  if (gmapsLoaded) {
    initializeMaps(event.layout)
  }
})
