import Page from 'lib/page'
import { SideMenu } from './navigation'
import CMTForm from 'plugins/form'
import DatePickerForm from './forms/date-picker-form'

// Set up side menu
let sideMenu = null
let sidemenuContainer = document.getElementById('side-panel')
if (sidemenuContainer) {
  sideMenu = new SideMenu(
    sidemenuContainer,
    document.getElementById('page')
  )
  const toggleButtons = document.querySelectorAll('a[href="#side-panel"]')
  for (let i = 0; i < toggleButtons.length; i++) {
    sideMenu.addToggler(toggleButtons[i])
  }
}

// Set up date picker forms
window.addEventListener('cmt-form-initialized', function (event) {
  if (event.detail.form.element.classList.contains('cmt-date-picker-form')) {
    event.detail.form.datePicker = new DatePickerForm(event.detail.form)
  }
})

// Set up forms
const forms = []
const formElements = document.querySelectorAll('form.cmt-form')
for (let i = 0; i < formElements.length; i++) {
  let formElement = formElements[i]
  forms.push(new CMTForm(formElement))
}

// A function which can be used globally to load and get the vjs chunk
let videojs
async function videoJs () {
  if (videojs) {
    return videojs
  }
  await import(/* webpackChunkName: "vjs" */ 'sass/vjs.scss')
  try {
    videojs = (await import(/* webpackChunkName: "vjs" */ 'video.js')).default
    return videojs
  } catch (error) {
    console.err(error)
    return false
  }
}

// Set up page
const page = new Page()

// Export
export { sideMenu, page, videoJs }
