import { videoJs } from 'instance'

class Slideshow {
  constructor (container) {
    this.container = container
    this.timeout = null
    this.prevSlide = null
    this.crntSlide = null
    this.classes = {
      crntSlide: 'slide-crnt',
      prevSlide: 'slide-prev',
      crntButton: 'slide-btn-crnt'
    }
    this.durationAttribute = 'duration'
    this.defaultDuration = 4000

    let slidesContainer = container.querySelector('.slides')
    this.usesTouch = slidesContainer.classList.contains('slides-touch')
    this.slides = [...slidesContainer.children]

    if (this.slides.length <= 1) {
      return
    }
    this._initialize()
  }

  async _initialize () {
    for (let slide of this.slides) {
      await this.initializeSlide(slide)
    }
    this.slideIndex = -1
    this.initializeControllers()
    this._ready = true
    if (this._running) {
      this.start()
    }
  }

  async initializeSlide (slide) {
    let video = slide.querySelector('video')
    if (video) {
      let videojs = await videoJs()
      if (!videojs) {
        return
      }

      slide.player = videojs(video, {}, () => {
        if (this.crntSlide === slide) {
          // It does not like playing the video unless a timeout is provided
          setTimeout(() => {
            slide.player.play()
            slide.playerReady = true
          }, 0)
        } else {
          slide.playerReady = true
        }
      })
      slide.player.on('ended', () => {
        if (this.crntSlide === slide) {
          this.slide()
        }
      })
    }
  }

  setController (element, index) {
    element.addEventListener('click', (e) => {
      this.slide(index)
    })
  }

  async initializeControllers () {
    let controllersContainer = this.container.querySelector('.slides-controller')
    if (controllersContainer) {
      this.controllers = [...controllersContainer.children]
    } else {
      this.controllers = null
    }

    if (this.controllers) {
      for (let i = 0; i < this.controllers.length; i++) {
        this.setController(this.controllers[i], i)
      }
    }

    if (this.usesTouch) {
      let Hammer
      try {
        Hammer = (await import(/* webpackChunkName: "hammer" */ 'hammerjs')).default
      } catch (err) {
        console.error(err)
        return
      }

      var userSelect = Hammer.defaults.cssProps.userSelect
      delete Hammer.defaults.cssProps.userSelect
      var hammertime = new Hammer(this.container, {
        inputClass: Hammer.TouchInput,
        recognizers: [
          [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]
        ]
      })
      Hammer.defaults.cssProps.userSelect = userSelect
      hammertime.on('swipe', (ev) => {
        if (ev.direction === Hammer.DIRECTION_LEFT) {
          this.slide(this.slideIndex + 1)
        } else if (ev.direction === Hammer.DIRECTION_RIGHT) {
          this.slide(this.slideIndex - 1)
        }
      })
    }
  }

  start () {
    this._running = true
    if (!this._ready) {
      return
    }
    this.slide(true)
  }

  stop () {
    this._running = false
    window.clearTimeout(this.timeout)
  }

  slide (index) {
    if (this.slides.length <= 1) {
      return
    }
    window.clearTimeout(this.timeout)
    if (typeof index === 'undefined') {
      index = true
    }

    if (this.prevSlide) {
      this.prevSlide.classList.remove(this.classes.prevSlide)
    }
    if (this.crntSlide) {
      this.crntSlide.classList.remove(this.classes.crntSlide)
      this.crntSlide.classList.add(this.classes.prevSlide)
      this.prevSlide = this.crntSlide
    }
    if (index === true) {
      this.slideIndex += 1
    } else if (index === false) {
      this.slideIndex -= 1
    } else {
      this.slideIndex = index
    }
    this.slideIndex = this.slideIndex % this.slides.length
    while (this.slideIndex < 0) {
      this.slideIndex = this.slides.length + this.slideIndex
    }
    this.crntSlide = this.slides[this.slideIndex]
    this.crntSlide.classList.remove(this.classes.prevSlide)
    this.crntSlide.classList.add(this.classes.crntSlide)
    if (this.controllers) {
      for (let i = 0; i < this.controllers.length; i++) {
        if (this.slideIndex === i) {
          this.controllers[i].classList.add(this.classes.crntButton)
        } else {
          this.controllers[i].classList.remove(this.classes.crntButton)
        }
      }
    }
    if (this.prevSlide && this.prevSlide.player) {
      // Pause video on previous slide
      this.prevSlide.player.pause()
    }
    if (this.crntSlide.player && this.crntSlide.playerReady) {
      // Go to start & play on current slide
      this.crntSlide.player.currentTime(0)
      this.crntSlide.player.play()
    }

    // Set timeout for next slide
    let slideDuration = this.defaultDuration
    if (this.crntSlide.hasAttribute('data-' + this.durationAttribute)) {
      slideDuration = parseInt(this.crntSlide.getAttribute('data-' + this.durationAttribute))
    }
    if (slideDuration > 0) {
      this.timeout = setTimeout(this.slide.bind(this), slideDuration)
    }
  }
}

window.addEventListener('cmpb-layout-loaded', function (event) {
  var sliders = event.layout.querySelectorAll('.slider-wrap')
  for (let i = 0, l = sliders.length; i < l; i++) {
    let slideshow = new Slideshow(sliders[i])
    slideshow.start()
  }
})
