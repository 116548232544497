import EventEmitterMicro from '../event-emitter-micro'

export default class BaseComponent extends EventEmitterMicro {
  constructor (section, element, componentName, breakpoint, scrollPosition, windowHeight, index) {
    if (arguments.length !== 7) {
      throw new Error("Incorrect number of arguments passed to BaseComponent check the constructor or BaseComponent.call method - argument's should be (section, element, componentName, breakpoint, scrollPosition, windowHeight, index)")
    }
    super()
    this.section = section
    this.element = element
    this.componentName = componentName
    this.index = index
    this.isEnabled = true
    this.rafWhenVisible = this.rafWhenVisible || false
  }

  destroy () {
    this.teardownEvents()
    super.destory()
  }

  setupEvents () {}
  teardownEvents () {}
  onSectionWillAppear (scrollPosition, windowHeight) {}
  activate () {}
  animateIn () {}
  onRequestAnimationFrame () {}
  deactivate () {}
  onScroll (event, scrollPosition, windowHeight) {}
  onSectionWillDisappear (scrollPosition, windowHeight) {}
  onResizeDebounced (event, scrollPosition, windowHeight) {}
  onResizeImmediate (event, scrollPosition, windowHeight) {}
  onOrientationChange (event, orientation, scrollPosition, windowHeight) {}
  onBreakpoint (to, from, scrollPosition, windowHeight) {}
}
