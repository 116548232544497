import BaseComponent from './base-component'
import ScrollAnimation from '../scroll-animation'
import viewportEmitter from '../viewport-emitter'

export default class ScrollAnimationComponent extends BaseComponent {
  constructor (section, element, componentName, breakpoint, scrollPosition, windowHeight, index) {
    super(...arguments)
    this.animationDefinitions = {}
    if (element.dataset.scrollAnimations) {
      try {
        this.animationDefinitions = JSON.parse(element.dataset.scrollAnimations)
      } catch (err) {
        throw new Error(`ScrollAnimationComponent invalid scroll animations data ${element.dataset.scrollAnimations}!`)
      }
    }
    this.section = section
    this.element = element
    this.breakpointName = breakpoint
    this.scrollAnimationItems = []
    this.rafWhenVisible = true
    this._memoizeMetrics(windowHeight)
    this._initialize()
  }

  _initialize () {
    if (!this.breakpointName) {
      return
    }
    var elements = this.element.querySelectorAll('[data-scroll-animation]:not(.scroll-animation-initialized)')
    var data
    for (var i = 0, l = elements.length; i < l; i++) {
      let element = elements[i]
      if (!element.offsetHeight) {
        continue
      }
      data = JSON.parse(element.dataset.scrollAnimation) || {}
      data.overrideScroll = true
      data.overrideResize = true
      data = this._formatOptions(data, this.breakpointName)
      this._setDefaultFriction(element, data)
      this.scrollAnimationItems.push(new ScrollAnimation(element, data))
    }
  }

  _memoizeMetrics (height) {
    this.windowHeight = height
  }

  _formatOptions (data, currentBreakpoint) {
    let breakpoints = viewportEmitter.getBreakpoints()
    var out = {}
    // inherit unprefixed values
    for (let [key, value] of Object.entries(data)) {
      if (breakpoints.indexOf(key) > -1) {
        continue
      }
      out[key] = value
    }
    // get values for current breakpoint
    let breakpointData = data[currentBreakpoint]
    if (breakpointData) {
      if (typeof breakpointData === 'string') {
        let animationDefinition = this.animationDefinitions[breakpointData]
        if (!animationDefinition) {
          console.log(`ScrollAnimationComponent non-existent animation '${breakpointData}'`)
        } else {
          for (let [key, value] of Object.entries(animationDefinition)) {
            out[key] = value
          }
        }
      } else {
        for (let [key, value] of Object.entries(breakpointData)) {
          out[key] = value
        }
      }
    }
    return out
  }

  _setDefaultFriction (element, data) {
    data.friction = data.friction || 10
  }

  _reInitialize () {
    this._destroyScrollAnimations()
    this._initialize()
    this.setupEvents()
  }

  _destroyScrollAnimations () {
    for (var i = 0, l = this.scrollAnimationItems.length; i < l; i++) {
      this.scrollAnimationItems[i].destroy()
      this.scrollAnimationItems[i] = null
    }
    this.scrollAnimationItems.length = 0
  }

  _handleScroll (scrollPosition) {
    for (var i = 0, l = this.scrollAnimationItems.length; i < l; i++) {
      this.scrollAnimationItems[i].handleScroll(scrollPosition)
    }
  }

  onScroll (event, scrollPosition, windowHeight) {
    super.onScroll(event, scrollPosition, windowHeight)
    this._handleScroll(scrollPosition)
  }

  onResizeImmediate (event, scrollPosition, windowHeight) {
    this._handleResizeImmediate(event, scrollPosition, windowHeight)
  }

  onOrientationChange (event, orientation, scrollPosition, windowHeight) {
    this._handleResizeImmediate(event, scrollPosition, windowHeight)
  }

  _handleResizeImmediate (event, scrollPosition, windowHeight) {
    this._memoizeMetrics(windowHeight)
    this._initialize() // Initialize all elements that aren't yet
    for (var i = 0, l = this.scrollAnimationItems.length; i < l; i++) {
      let item = this.scrollAnimationItems[i]
      item.handleResize(scrollPosition)
    }
  }

  onBreakpoint (to, from, scrollPosition, windowHeight) {
    this.breakpointName = to
    this._reInitialize()
  }

  destroy () {
    this._destroyScrollAnimations()
    super.destroy()
  }
}
