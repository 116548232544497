const hasOwnProperty = Object.prototype.hasOwnProperty

export function extend () {
  var objects
  if (arguments.length < 2) {
    objects = [{}, arguments[0]]
  } else {
    objects = [].slice.call(arguments)
  }
  let result = objects.shift()
  objects.forEach((obj) => {
    if (obj != null) {
      for (let key in obj) {
        if (hasOwnProperty.call(obj, key)) {
          result[key] = obj[key]
        }
      }
    }
  })
  return result
}

function _deepclone (obj1, obj2) {
  for (let key in obj2) {
    if (hasOwnProperty.call(obj2, key)) {
      if (obj2[key] === null) {
        obj1[key] = null
      } else {
        if (typeof obj2[key] === 'object') {
          obj1[key] = Array.isArray(obj2[key]) ? [] : {}
          _deepclone(obj1[key], obj2[key])
        } else {
          obj1[key] = obj2[key]
        }
      }
    }
  }
  return obj1
}

export function clone (obj, deepClone) {
  if (deepClone) {
    return _deepclone({}, obj)
  }
  return extend({}, obj)
}

export function defaults (defaults, options = {}) {
  if (typeof defaults !== 'object') {
    throw new TypeError('defaults: must provide a defaults object')
  }
  if (typeof options !== 'object') {
    throw new TypeError('defaults: options must be a typeof object')
  }
  return extend({}, defaults, options)
}
