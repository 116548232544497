import EventEmitterMicro from './event-emitter-micro'
import sharedClock from './clock'

export default class MotionEmitter extends EventEmitterMicro {
  constructor (options) {
    super()
    this.options = options || {}
    this.min = this.options.min || 0
    this.max = this.options.max || 1
    this._boundHandleClockUpdate = this._handleClockUpdate.bind(this)
    this._boundHandleClockDraw = this._handleClockDraw.bind(this)
    if (this.options.easingFunction) {
      this.easingFunction = this.options.easingFunction
    }
    this.clock = this.options.clock || sharedClock
    this.usesSharedClock = (this.clock === sharedClock)
    this._isRunning = false
    this.specificity = this.options.specificity || 4
    this.friction = this.options.friction || 10
    this._targetValue = null
    this._currentValue = null
    this._shouldUpdate = false
    this._shouldEmitChange = false
  }

  destroy () {
    this.trigger('destroy')
    this.stop()
    this.off()
    if (!this.usesSharedClock) {
      this.clock.destroy()
    }
    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        this[prop] = null
      }
    }
    this._isRunning = false
  }

  start () {
    if (!this.clock || this._isRunning) {
      return
    }
    this._bindEvents()
    this._isRunning = true
    this.clock.start()
  }

  stop () {
    if (!this.clock || !this._isRunning) {
      return
    }
    this._unbindEvents()
    this._isRunning = false
    if (!this.usesSharedClock) {
      this.clock.stop()
    }
  }

  isRunning () {
    return this._isRunning
  }

  setProgress (value) {
    if (this._targetValue === value) {
      return
    }
    this._targetValue = value
    this._shouldUpdate = true
  }

  updateValue (event) {
    if (this._currentValue === null) {
      this._currentValue = this._targetValue
    }
    var f = 1
    if (this.easingFunction) {
      let w = this.max - this.min
      let v = this.max - (this.max - this._targetValue) / w
      let c = this.max - (this.max - this._currentValue) / w
      let u = 1 - Math.abs(v - c)
      let b = this.easingFunction(u, 0, 1, 1)
      f = 1 + (b - u)
    }
    var a = 1
    if (event && event.naturalFps !== event.fps) {
      a = event.naturalFps / event.fps
    }
    var i = this._targetValue - this._currentValue
    var h = i * f * a * (1 / this.friction)
    var g = parseFloat((this._currentValue + h).toFixed(this.specificity))
    if (g === this._currentValue) {
      this._currentValue = this._targetValue
    } else {
      this._currentValue = g
    }
    this._shouldEmitChange = true
  }

  _bindEvents () {
    this.clock.on('update', this._boundHandleClockUpdate)
    this.clock.on('draw', this._boundHandleClockDraw)
  }

  _unbindEvents () {
    this.clock.off('update', this._boundHandleClockUpdate)
    this.clock.off('draw', this._boundHandleClockDraw)
  }

  _handleClockUpdate (event) {
    if (this._shouldUpdate) {
      this.updateValue(event)
    }
    if (!this._shouldEmitChange) {
      return
    }
    event.progress = this._currentValue
    this.trigger('update', event)
  }

  _handleClockDraw (event) {
    if (!this._shouldEmitChange) {
      return
    }
    event.progress = this._currentValue
    this.trigger('draw', event)
    if (this._targetValue === this._currentValue) {
      this._shouldUpdate = false
      this._shouldEmitChange = false
      return
    }
    this._shouldUpdate = true
  }
}
