import EventEmitterMicro from 'lib/event-emitter-micro'
import scrollTo from 'lib/helpers/scrollTo'
import {getPagePosition} from 'lib/helpers/dom-metrics'

const bodyEle = document.body

export function softScrollHash (item, duration = 250) {
  if (
    item.hash.length &&
    window.location.hostname === item.hostname &&
    window.location.pathname.replace(/^\//, '') === item.pathname.replace(/^\//, '')
  ) {
    item.addEventListener('click', (event) => {
      event.preventDefault()
      window.history.pushState({}, document.title, item.pathname + item.hash)
      let target = document.querySelector(item.hash)
      if (target) {
        scrollTo(getPagePosition(target).top, duration)
      }
    })
  }
}

export function softScrollHashes (containers, duration = 250) {
  containers = containers || [bodyEle]
  if (containers instanceof window.NodeList) {
    containers = Array.from(containers)
  } else if (!Array.isArray(containers)) {
    containers = [containers]
  }
  for (let container of containers) {
    let items = Array.from(container.querySelectorAll('a'))
    for (let item of items) {
      softScrollHash(item, duration)
    }
  }
}

export class SideMenu extends EventEmitterMicro {
  constructor (element, overlay, toggleClass = 'side-panel-toggled') {
    super()
    this.element = element
    this.overlay = overlay
    this.toggleClass = toggleClass
    this.open = false
    this.togglers = []
    this.toggle = this.toggle.bind(this)
    this.checkMenuLink = this.checkMenuLink.bind(this)

    const links = element.querySelector('.menu').getElementsByTagName('a')
    for (let i = 0, len = links.length; i < len; i++) {
      links[i].addEventListener('click', this.checkMenuLink)
    }
  }

  checkMenuLink (event) {
    const a = event.currentTarget
    /* Toggle submenus
    const item = a.parentElement
    if (item.classList.contains('menu-item-has-children')) {
      item.classList.toggle('menu-item-open')
      event.preventDefault()
      return
    }
    */
    const location = window.location
    if (
      a.hash.length &&
      location.hostname === a.hostname &&
      location.pathname.replace(/^\//, '') === a.pathname.replace(/^\//, '')
    ) {
      this.toggle()
    }
  }

  toggle () {
    if (this.open) {
      bodyEle.classList.remove(this.toggleClass)
      if (this.overlay) {
        this.overlay.removeEventListener('click', this.toggle)
      }
    } else {
      bodyEle.classList.add(this.toggleClass)
      if (this.overlay) {
        this.overlay.addEventListener('click', this.toggle)
      }
    }
    this.open = !this.open
    const expanded = this.open ? 'true' : 'false'
    this.element.setAttribute('aria-expanded', expanded)
    for (let i = 0; i < this.togglers.length; i++) {
      this.togglers[i].setAttribute('aria-expanded', expanded)
    }
    this.trigger('toggle', this.open)
    if (this.open) {
      this.trigger('open')
    } else {
      this.trigger('close')
    }
  }

  addToggler (element) {
    element.addEventListener('click', (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.toggle()
    })
    element.setAttribute('aria-expanded', this.open ? 'true' : 'false')
    this.togglers.push(element)
  }
}
