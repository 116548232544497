import ElementTracker from './element-tracker'
import {defaults, extend} from './helpers/object'

const defaultOptions = {
  timeToEngage: 500,
  inViewThreshold: 0.75,
  stopOnEngaged: true
}

const overrideOptions = {
  thresholdEnterTime: 0,
  thresholdExitTime: 0,
  inThreshold: false,
  engaged: false,
  tracking: true
}

export default class ElementEngagement extends ElementTracker {
  constructor () {
    super()
    this._thresholdEnter = this._thresholdEnter.bind(this)
    this._thresholdExit = this._thresholdExit.bind(this)
    this._enterView = this._enterView.bind(this)
    this._exitView = this._exitView.bind(this)
  }

  _decorateTrackedElement (trackedElement, options) {
    let optionsWithDefaults = defaults(defaultOptions, options || {})
    extend(trackedElement, optionsWithDefaults)
    extend(trackedElement, overrideOptions)
  }

  _attachElementListeners (trackedElement) {
    trackedElement.on('thresholdenter', this._thresholdEnter, this)
    trackedElement.on('thresholdexit', this._thresholdExit, this)
    trackedElement.on('enterview', this._enterView, this)
    trackedElement.on('exitview', this._exitView, this)
  }

  _removeElementListeners (trackedElement) {
    trackedElement.off('thresholdenter', this._thresholdEnter)
    trackedElement.off('thresholdexit', this._thresholdExit)
    trackedElement.off('enterview', this._enterView)
    trackedElement.off('exitview', this._exitView)
  }

  _attachAllElementListeners () {
    this.elements.forEach((trackedElement) => {
      if (!trackedElement.stopOnEngaged) {
        this._attachElementListeners(trackedElement)
      } else {
        if (!trackedElement.engaged) {
          this._attachElementListeners(trackedElement)
        }
      }
    })
  }

  _removeAllElementListeners () {
    this.elements.forEach((trackedElement) => {
      this._removeElementListeners(trackedElement)
    })
  }

  _elementInViewPastThreshold (trackedElement) {
    var clientHeight = window.innerHeight || document.documentElement.clientHeight
    var inViewPastThreshold = false
    if (trackedElement.pixelsInView === clientHeight) {
      inViewPastThreshold = true
    } else {
      inViewPastThreshold = (trackedElement.percentInView > trackedElement.inViewThreshold)
    }
    return inViewPastThreshold
  }

  _ifInView (trackedElement, alreadyInView) {
    var inThreshold = trackedElement.inThreshold
    super._ifInView.apply(this, arguments)
    if (!inThreshold && this._elementInViewPastThreshold(trackedElement)) {
      trackedElement.inThreshold = true
      trackedElement.trigger('thresholdenter', trackedElement)
      if (typeof trackedElement.timeToEngage === 'number' && trackedElement.timeToEngage >= 0) {
        trackedElement.engagedTimeout = window.setTimeout(this._engaged.bind(this, trackedElement), trackedElement.timeToEngage)
      }
    }
  }

  _ifAlreadyInView (trackedElement) {
    var inThreshold = trackedElement.inThreshold
    super._ifAlreadyInView.apply(this, arguments)
    if (inThreshold && !this._elementInViewPastThreshold(trackedElement)) {
      trackedElement.inThreshold = false
      trackedElement.trigger('thresholdexit', trackedElement)
      if (trackedElement.engagedTimeout) {
        window.clearTimeout(trackedElement.engagedTimeout)
        trackedElement.engagedTimeout = null
      }
    }
  }

  _engaged (trackedElement) {
    trackedElement.engagedTimeout = null
    this._elementEngaged(trackedElement)
    trackedElement.trigger('engaged', trackedElement)
    this.trigger('engaged', trackedElement)
  }

  _thresholdEnter (trackedElement) {
    trackedElement.thresholdEnterTime = Date.now()
    trackedElement.thresholdExitTime = 0
    this.trigger('thresholdenter', trackedElement)
  }

  _thresholdExit (trackedElement) {
    trackedElement.thresholdExitTime = Date.now()
    this.trigger('thresholdexit', trackedElement)
  }

  _enterView (trackedElement) {
    this.trigger('enterview', trackedElement)
  }

  _exitView (trackedElement) {
    this.trigger('exitview', trackedElement)
  }

  _elementEngaged (trackedElement) {
    trackedElement.engaged = true
    if (trackedElement.stopOnEngaged) {
      this.stop(trackedElement)
    }
  }

  stop (trackedElement) {
    if (this.tracking && !trackedElement) {
      this._removeAllElementListeners()
      super.stop()
    }
    if (trackedElement && trackedElement.tracking) {
      trackedElement.tracking = false
      this._removeElementListeners(trackedElement)
    }
  }

  start (trackedElement) {
    if (!trackedElement) {
      this._attachAllElementListeners()
    }
    if (trackedElement && !trackedElement.tracking) {
      if (!trackedElement.stopOnEngaged) {
        trackedElement.tracking = true
        this._attachElementListeners(trackedElement)
      } else {
        if (!trackedElement.engaged) {
          trackedElement.tracking = true
          this._attachElementListeners(trackedElement)
        }
      }
    }
    if (!this.tracking) {
      super.start()
    } else {
      this.refreshAllElementMetrics()
      this.refreshAllElementStates()
    }
  }

  addElement (element, options) {
    var trackedElement = super.addElement(element)
    this._decorateTrackedElement(trackedElement, options)
    return trackedElement
  }

  addElements (elements, options) {
    [].forEach.call(elements, (element) => {
      this.addElement(element, options)
    })
  }
}
