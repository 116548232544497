import scrollTo from 'lib/helpers/scrollTo'
import {ScrollMotionEmitter} from 'lib/scroll-motion-emitter'
// import viewportEmitter from 'lib/viewport-emitter'

var goToTopBtn = document.querySelector('.go-to-top')
if (goToTopBtn) {
  goToTopBtn.addEventListener('click', (event) => {
    event.preventDefault()
    scrollTo(0, 250, () => {
      if (document.activeElement === goToTopBtn) {
        document.activeElement.blur()
      }
    })
  })

  var winHeight = window.innerHeight
  var scrollMotionEmitter = new ScrollMotionEmitter({
    smooth: false,
    overrideScroll: false,
    min: winHeight / 2,
    max: winHeight,
    friction: 0
  })

  scrollMotionEmitter.on('update', (a) => {
    goToTopBtn.style.opacity = a.progress
    if (a.progress === 0) {
      goToTopBtn.style.visibility = 'hidden'
    } else {
      goToTopBtn.style.visibility = 'visible'
    }
  })

  if (!scrollMotionEmitter.isRunning()) {
    scrollMotionEmitter.start()
    scrollMotionEmitter.handleScroll() // Force initial scroll
  }

  /* NOTE: Uncomment this and viewportEmitter if go to top should stop above the footer
  let siteFooter = document.querySelector('.site-footer')
  let goToTopSticky = function (scrollTop) {
    let height = siteFooter.offsetHeight
    var scrollBottom = document.body.offsetHeight - scrollTop - viewportEmitter.clientHeight()
    if (scrollBottom < height) {
      goToTopBtn.classList.add('go-to-top-sticky')
      goToTopBtn.style.marginBottom = height + 'px'
    } else {
      goToTopBtn.classList.remove('go-to-top-sticky')
      goToTopBtn.style.marginBottom = ''
    }
  }
  viewportEmitter.on('scroll', () => {
    goToTopSticky(viewportEmitter.scrollY())
  })
  goToTopSticky(viewportEmitter.scrollY()) // Force initial sticky state
  */
}
