const NAME_PREFIX = '_cmt-form-'

class CMTForm {
  constructor (form) {
    if (form.dataset.formInitialized) {
      console.warn('CMTForm: Same form was initialized twice.')
    } else {
      form.dataset.formInitialized = true
      this.element = form
      this.submitElements = form.querySelectorAll('[type="submit"]')
      this.responseElement = form.querySelector('.cmt-form-response')
      this.inputs = form.elements
      this.strings = JSON.parse(form.dataset.strings)
      this._disabled = false
      form.addEventListener('submit', (event) => {
        event.preventDefault()
        if (this._disabled) {
          return false
        }
        this.handleSubmit()
      })
      let event = new window.CustomEvent('cmt-form-initialized', {
        'bubbles': true,
        'cancelable': false,
        'detail': {
          form: this,
          form_id: this.inputs['cmt-form'].value,
          form_uid: this.inputs['cmt-form-uid'].value
        }
      })
      window.dispatchEvent(event)
    }
  }

  disableSubmit () {
    this._disabled = true
    for (let i = 0, l = this.submitElements.length; i < l; i++) {
      this.submitElements[i].disabled = true
    }
  }

  enableSubmit () {
    this._disabled = false
    for (let i = 0, l = this.submitElements.length; i < l; i++) {
      this.submitElements[i].disabled = false
    }
  }

  getInputByName (name) {
    return this.inputs[NAME_PREFIX + name]
  }

  getValues () {
    let values = {}
    for (let i = 0; i < this.inputs.length; i++) {
      let input = this.inputs[i]
      let value
      if (input.tagName === 'SELECT') {
        value = input.options[input.selectedIndex].value
      } else if (input.tagName === 'INPUT' && (input.type === 'radio' || input.type === 'checkbox')) {
        if (input.checked) {
          value = input.value
        } else {
          continue
        }
      } else {
        value = input.value
      }
      values[input.name] = value
    }
    return values
  }

  buildQuery () {
    const values = this.getValues()
    let query = []
    for (let key of Object.keys(values)) {
      query.push(key + '=' + encodeURIComponent(values[key]))
    }
    return query.join('&')
  }

  setResponse (message, success) {
    this.responseElement.innerHTML = message
    if (success) {
      this.responseElement.classList.add('cmt-form-success')
    } else {
      this.responseElement.classList.add('cmt-form-failure')
    }
  }

  setSending () {
    this.responseElement.classList.remove('cmt-form-success')
    this.responseElement.classList.remove('cmt-form-failure')
    this.responseElement.innerHTML = this.strings.sending
  }

  handleResponse (status, response) {
    if (status === 200) {
      var resp = JSON.parse(response)
      console.log(resp)
      if (resp.success === true) {
        this.setResponse(this.strings.sent, true)
        // Create the event.
        let event = new window.CustomEvent('cmt-form-sent', {
          'bubbles': true,
          'cancelable': false,
          'detail': {
            form: this,
            form_id: this.inputs['cmt-form'].value,
            form_uid: this.inputs['cmt-form-uid'].value
          }
        })
        window.dispatchEvent(event)
      } else {
        let err
        if (!resp.errors || !resp.errors.length) {
          err = this.strings.error
        } else {
          err = resp.errors.join('<br>')
        }
        this.setResponse(err, false)
        this.enableSubmit()
      }
    } else {
      this.setResponse(this.strings.error, false)
      this.enableSubmit()
    }
  }

  handleSubmit () {
    this.disableSubmit()
    this.setSending()
    let xmlhttp = new window.XMLHttpRequest()
    xmlhttp.onreadystatechange = () => {
      if (xmlhttp.readyState === 4) {
        this.handleResponse(xmlhttp.status, xmlhttp.response)
      }
    }
    xmlhttp.open('POST', window.CMTConfig.rest + 'form/send', true)
    xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xmlhttp.send(this.buildQuery())
  }
}

export default CMTForm
