// Learn more: https://git.io/vWdr2
const isIe = /(trident|msie)/i.test(navigator.userAgent)
if (isIe) {
  window.addEventListener('hashchange', function () {
    var id = window.location.hash.substring(1)
    var element
    if (!(/^[A-z0-9_-]+$/.test(id))) {
      return
    }
    element = document.getElementById(id)
    if (element) {
      if (!(/^(?:a|select|input|button|textarea)$/i.test(element.tagName))) {
        element.tabIndex = -1
      }
      element.focus()
    }
  }, false)
}
