import viewportEmitter from 'lib/viewport-emitter'
import { getPagePosition } from 'lib/helpers/dom-metrics'
const sectionTextWrap = document.querySelector('.section-menu-text-wrap')
const sectionText = document.querySelector('.section-menu-text')
if (sectionText) {
  const sections = []
  for (let layout of document.body.querySelectorAll('.cmpb-layout[data-name]')) {
    sections.push({
      element: layout,
      title: layout.dataset.name
    })
  }
  for (let anchor of document.body.querySelectorAll('.cmpb-block-anchor[data-anchor]')) {
    sections.push({
      element: anchor,
      title: anchor.dataset.anchor
    })
  }
  if (sections.length > 1) {
    let currentSection = null
    const updateCurrentSection = function (scrollPosition, windowHeight) {
      var newSection = sections[0]
      var highestPosition = -Infinity

      for (let i = 0, l = sections.length; i < l; i++) {
        let section = sections[i]
        let position = getPagePosition(section.element)
        if (position.top >= highestPosition && position.top - scrollPosition < windowHeight / 2) {
          newSection = section
          highestPosition = position.top
        }
      }

      if (currentSection !== newSection) {
        sectionText.innerHTML = newSection.title
        sectionTextWrap.style.width = sectionText.offsetWidth + 'px'
        currentSection = newSection
      }
    }
    const onScroll = function () {
      updateCurrentSection(
        viewportEmitter.scrollY(),
        viewportEmitter.clientHeight()
      )
    }
    viewportEmitter.on('scroll', onScroll)
    sectionTextWrap.style.display = 'block'
    onScroll()
  } else {
    sectionTextWrap.style.display = 'none'
  }
  // Fix text wrap width when the section menu is shown
  let breakpoint = window.matchMedia(`(min-width: ${window.CMTConfig.variables.menu_breakpoint * window.CMTConfig.variables.rem_modifier}em)`)
  breakpoint.addListener((event) => {
    if (!event.matches) {
      sectionTextWrap.style.width = sectionText.offsetWidth + 'px'
    }
  })
}
