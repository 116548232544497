import {getScrollY} from './dom-metrics'
import {easeInOutQuad} from './easings'

export default function scrollTo (to = 0, duration = 500, callback = false) {
  // because it's so fucking difficult to detect the scrolling element, just move them all
  function move (amount) {
    document.documentElement.scrollTop = amount
    document.body.parentNode.scrollTop = amount
    document.body.scrollTop = amount
  }
  var start = getScrollY()
  var change = to - start
  var currentTime = 0
  var increment = 20
  var animateScroll = function () {
    currentTime += increment
    var val = easeInOutQuad(Math.min(currentTime, duration), start, change, duration)
    move(val)
    if (currentTime < duration) {
      window.requestAnimationFrame(animateScroll)
    } else {
      if (callback && typeof (callback) === 'function') {
        callback()
      }
    }
  }
  animateScroll()
}
