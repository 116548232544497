import EventEmitterMicro from './event-emitter-micro'

class ViewportEmitter extends EventEmitterMicro {
  constructor () {
    super()

    this.breakpoint = ''

    this.scrollY(true)
    this.clientHeight(true)

    this._onScroll = this._onScroll.bind(this)
    this._onResizeImmediate = this._onResizeImmediate.bind(this)
    this._onOrientationChange = this._onOrientationChange.bind(this)
    this._onBreakpointChange = this._onBreakpointChange.bind(this)

    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('resize', this._onResizeImmediate)
    window.addEventListener('orientationchange', this._onOrientationChange)

    // When everything in DOM is ready, we trigger a resize event to update positioning
    document.addEventListener('DOMContentLoaded', () => {
      window.dispatchEvent(new window.CustomEvent('resize'))
    })

    /*
    if (document.fonts) {
      document.fonts.ready.then(function () {
        window.dispatchEvent(new window.CustomEvent('resize'))
      })
    }
    */
  }

  _onScroll (event) {
    this.scrollY(true)
    this.trigger('scroll', event)
  }

  _onResizeImmediate (event) {
    this.scrollY(true)
    this.clientHeight(true)
    this.trigger('resize', event)
  }

  _onOrientationChange (event) {
    this.trigger('orientationchange', event)
  }

  _onBreakpointChange () {
    let from = this.breakpoint
    this.breakpoint = this._defaultBreakpoint
    for (let [key, list] of this._breakpoints) {
      if (list.matches) {
        this.breakpoint = key
        break
      }
    }
    if (from !== this.breakpoint) {
      this.trigger('breakpoint', {
        from: from,
        to: this.breakpoint
      })
    }
  }

  scrollY (update) {
    if (update) {
      var doc = document.documentElement
      this.cachedScrollY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    }
    return this.cachedScrollY
  }

  clientHeight (update) {
    if (update) {
      this.cachedClientHeight = document.documentElement.clientHeight || window.innerHeight
    }
    return this.cachedClientHeight
  }

  setBreakpoints (defaultBreakpoint, breakpoints) {
    this.breakpoint = defaultBreakpoint
    this._defaultBreakpoint = defaultBreakpoint
    // remove old breakpoint listeners
    if (this._breakpoints) {
      for (let [, list] of this._breakpoints) {
        list.removeListener(this._onMatchMediaChange)
      }
    }
    this._breakpoints = []
    // Set up media query listeners
    for (let [key, value] of breakpoints) {
      let list = window.matchMedia(value)
      this._breakpoints.unshift([key, list])
      if (list.matches) {
        this.breakpoint = key
      }
      list.addListener(this._onBreakpointChange)
    }

    this.trigger('breakpoint', {
      from: '',
      to: this.breakpoint
    })
  }

  getBreakpoints () {
    return [this._defaultBreakpoint].concat(this._breakpoints.map(([key]) => key))
  }

  destroy () {
    window.removeEventListener('scroll', this._onScroll)
    window.removeEventListener('resize', this._onResizeImmediate)
    window.removeEventListener('orientationchange', this._onOrientationChange)
  }
}

const viewportEmitter = new ViewportEmitter()

export default viewportEmitter
