const transitionend = {
  'animation-delay': 'transitionend',
  '-o-animation-delay': 'oTransitionEnd',
  '-moz-animation-delay': 'transitionend',
  '-webkit-animation-delay': 'webkitTransitionEnd',
  '-ms-animation-delay': 'transitionend'
}
const animationstart = {
  'animation-delay': 'animationstart',
  '-o-animation-delay': 'oanimationstart',
  '-moz-animation-delay': 'animationstart',
  '-webkit-animation-delay': 'webkitAnimationStart',
  '-ms-animation-delay': 'MSAnimationStart'
}
const animationiteration = {
  'animation-delay': 'animationiteration',
  '-o-animation-delay': 'oanimationiteration',
  '-moz-animation-delay': 'animationiteration',
  '-webkit-animation-delay': 'webkitAnimationIteration',
  '-ms-animation-delay': 'MSAnimationIteration'
}
const animationend = {
  'animation-delay': 'animationend',
  '-o-animation-delay': 'oanimationend',
  '-moz-animation-delay': 'animationend',
  '-webkit-animation-delay': 'webkitAnimationEnd',
  '-ms-animation-delay': 'MSAnimationEnd'
}

const testEle = document.createElement('_')

const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-']
function prefix (property) {
  for (let i = 0; i < prefixes.length; i++) {
    let prefixed = prefixes[i] + property
    if (testEle.style[prefixed] !== undefined) {
      return prefixed
    }
  }
  return undefined
}

const prefixesWebkit = ['-webkit-', '', '-moz-', '-o-', '-ms-']
function prefixWebkit (property) {
  for (let i = 0; i < prefixesWebkit.length; i++) {
    let prefixed = prefixesWebkit[i] + property
    if (testEle.style[prefixed] !== undefined) {
      return prefixed
    }
  }
  return undefined
}

export const filter = prefixWebkit('filter')
export const transform = prefix('transform')
export const transformOrigin = prefix('transform-origin')
export const transition = prefix('transition')
export const transitionDelay = prefix('transition-delay')
export const transitionDuration = prefix('transition-duration')
export const transitionProperty = prefix('transition-property')
export const transitionTimingFunction = prefix('transition-timing-function')
export const transitionEnd = transitionend[prefix('animation-delay')]
export const animation = prefix('animation')
export const animationDelay = prefix('animation-delay')
export const animationDirection = prefix('animation-direction')
export const animationDuration = prefix('animation-duration')
export const animationFillMode = prefix('animation-fill-mode')
export const animationIterationCount = prefix('animation-iteration-count')
export const animationName = prefix('animation-name')
export const animationTimingFunction = prefix('animation-timing-function')
export const animationPlayState = prefix('animation-play-state')
export const animationStart = animationstart[prefix('animation-delay')]
export const animationIteration = animationiteration[prefix('animation-delay')]
export const animationEnd = animationend[prefix('animation-delay')]
