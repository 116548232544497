// TODO: Add proper names to arguments

export function getBoundingClientRect (element) {
  var rect = element.getBoundingClientRect()
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width || rect.right - rect.left,
    height: rect.height || rect.bottom - rect.top
  }
}

export function getContentDimensions (element, a) {
  var scale = 1
  if (a) {
    scale = getBoundingClientRect(element).width / element.offsetWidth
  }
  return {
    width: element.scrollWidth * scale,
    height: element.scrollHeight * scale
  }
}

export function getDimensions (element, a) {
  if (a) {
    var rect = getBoundingClientRect(element)
    return {
      width: rect.width,
      height: rect.height
    }
  }
  return {
    width: element.offsetWidth,
    height: element.offsetHeight
  }
}

export function getPagePosition (element, f) {
  if (f) {
    let rect = getBoundingClientRect(element)
    let scrollX = getScrollX()
    let scrollY = getScrollY()
    return {
      top: rect.top + scrollY,
      right: rect.right + scrollX,
      bottom: rect.bottom + scrollY,
      left: rect.left + scrollX
    }
  }
  let dimensions = getDimensions(element, f)
  let rect = {
    top: element.offsetTop,
    left: element.offsetLeft,
    width: dimensions.width,
    height: dimensions.height
  }
  while ((element = element.offsetParent)) {
    rect.top += element.offsetTop
    rect.left += element.offsetLeft
  }
  return {
    top: rect.top,
    right: rect.left + rect.width,
    bottom: rect.top + rect.height,
    left: rect.left
  }
}

export function getPercentInViewport (element, a) {
  var c = getPixelsInViewport(element, a)
  var d = getDimensions(element, a).height
  return (c / d)
}

export function getPixelsInViewport (element, a) {
  var b = document.documentElement.clientHeight
  var f = getViewportPosition(element, a)
  var c
  if (f.top >= b || f.bottom <= 0) {
    return 0
  }
  c = (f.bottom - f.top)
  if (f.top < 0) {
    c += f.top
  }
  if (f.bottom > b) {
    c -= f.bottom - b
  }
  return c
}

export function getPosition (element, a) {
  var rect
  if (a) {
    rect = getBoundingClientRect(element)
    if (element.offsetParent) {
      let parentRect = getBoundingClientRect(element.offsetParent)
      rect.top -= parentRect.top
      rect.left -= parentRect.left
    }
  } else {
    let dimensions = getDimensions(element, a)
    rect = {
      top: element.offsetTop,
      left: element.offsetLeft,
      width: dimensions.width,
      height: dimensions.height
    }
  }
  return {
    top: rect.top,
    right: rect.left + rect.width,
    bottom: rect.top + rect.height,
    left: rect.left
  }
}

export function getScrollX (element) {
  element = element || window
  if (element === window) {
    let pos = window.pageXOffset
    if (!pos) {
      element = document.documentElement || document.body.parentNode || document.body
    } else {
      return pos
    }
  }
  return element.scrollLeft
}

export function getScrollY (element) {
  element = element || window
  if (element === window) {
    let pos = window.pageYOffset
    if (!pos) {
      element = document.documentElement || document.body.parentNode || document.body
    } else {
      return pos
    }
  }
  return element.scrollTop
}

export function getViewportPosition (element, f) {
  if (f) {
    let rect = getBoundingClientRect(element)
    return {
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left
    }
  }
  let pagePosition = getPagePosition(element)
  let scrollX = getScrollX()
  let scrollY = getScrollY()
  return {
    top: pagePosition.top - scrollY,
    right: pagePosition.right - scrollX,
    bottom: pagePosition.bottom - scrollY,
    left: pagePosition.left - scrollX
  }
}

export function isInViewport (element, a, d) {
  var c
  d = d || 0
  if (typeof d === 'string' && d.slice(-2) === 'px') {
    d = parseInt(d, 10)
    c = getPixelsInViewport(element, a)
  } else {
    c = getPercentInViewport(element, a)
  }
  return (c > 0 && c >= d)
}

let addressBarHeight = null
export function getAddressBarHeight () {
  if (addressBarHeight === null) {
    let testEle = document.createElement('div')
    testEle.style = 'position:fixed;height:100%'
    document.body.appendChild(testEle)
    let viewportHeight = testEle.offsetHeight
    testEle.style = 'position:fixed;height:100vh'
    let vhHeight = testEle.offsetHeight
    document.body.removeChild(testEle)
    addressBarHeight = vhHeight - viewportHeight
  }
  return addressBarHeight
}
