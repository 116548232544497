import {clone} from './helpers/object'
import {getDimensions, getPagePosition} from './helpers/dom-metrics'
import viewportEmitter from './viewport-emitter'
import {lerp} from './helpers/math-utils'
import {transform as prefixedTransform} from './helpers/browser-prefixed'
import {ScrollMotionEmitter, ElementScrollMotionEmitter} from './scroll-motion-emitter'

const defaultOptions = {
  duration: [0.5, 0.5, 0], // [viewport%, element%, pixel modifier]
  delay: [1.0, 0.0, 0], // [viewport%, element%, pixel modifier]
  friction: 4,
  translate: {0.0: [0, 0]},
  scale: {0.0: [1, 1]},
  rotate: {0.0: 0},
  fade: {0.0: 1},
  clipPath: {0.0: [0, 0, 0, 0]},
  clipPathColor: '#fff',
  overrideScroll: false,
  overrideResize: false,
  elementScroll: false,
  smooth: true,
  scrollMotionEmitter: null
}
const transforms = ['translate', 'scale', 'rotate']

// All browsers that support clip-path also support window.CSS.supports!
const canUseClipPath = window.CSS && window.CSS.supports('clip-path', 'inset(1px)')

export default class ScrollAnimation {
  constructor (element, options) {
    this.el = element
    this.options = this._overrideDefaultOptions(options)
    this.transforms = {}
    this._update = this._update.bind(this)
    this._memoizeMetrics()
    this._setEmitterBounds()
    this._setupClipPath()
    this._initScrollMotionEmitter()
    this._setupEvents()
    this._isAnimating = false
    this.el.classList.add('scroll-animation-initialized')
    this.handleScroll(viewportEmitter.scrollY())
  }

  destroy () {
    this.el.classList.remove('scroll-animation-initialized')
    this._teardownEvents()
    this.scrollMotionEmitter.destroy()
    this.scrollMotionEmitter = null
    if (this.hasTransform) {
      this.el.style[prefixedTransform] = this.initialTransform
    }
    if (this.hasClipPath) {
      this.el.style.clipPath = this.initialClipPath
      if (this.clipElements) {
        for (let i = 0, l = 4; i < l; i++) {
          let ele = this.clipElements[i]
          if (ele) {
            ele.parentElement.removeChild(ele)
          }
        }
      }
    }
    if (this.hasOpacity) {
      this.el.style.opacity = this.initialOpacity
    }
    this.el = null
    this.options = null
  }

  setOption (option, value) {
    this.options[option] = value
    if (option === 'duration' || option === 'delay') {
      this._setEmitterBounds()
    }
  }

  handleScroll (scrollPosition) {
    this.scrollMotionEmitter.handleScroll(scrollPosition)
  }

  handleResize (scrollPosition) {
    this._memoizeMetrics()
    this._setEmitterBounds()
    if (this.options.elementScroll) {
      this.scrollMotionEmitter.handleResize()
    }
    this.handleScroll(scrollPosition)
  }

  getTransform (transform) {
    return this.transforms[transform]
  }

  getOpacity () {
    return this.opacity
  }

  _overrideDefaultOptions (options) {
    var outOptions = Object.assign(clone(defaultOptions), options)
    var option
    for (option in options) {
      if (transforms.indexOf(option) > -1) {
        this.hasTransform = true
        this.initialTransform = this.el.style[prefixedTransform] || null
      } else if (option === 'fade') {
        this.hasOpacity = true
        this.initialOpacity = this.el.style.opacity || null
      } else if (option === 'clipPath') {
        this.hasClipPath = true
        this.initialClipPath = this.el.style.clipPath || null
      }
    }
    return outOptions
  }

  _setEmitterBounds () {
    let delay = this.options.delay[2]
    delay += this.windowHeight * this.options.delay[0]
    delay += this.elHeight * this.options.delay[1]
    this._emitterMin = this.elTop - delay

    let duration = this.options.duration[2]
    duration += this.windowHeight * this.options.duration[0]
    duration += this.elHeight * this.options.duration[1]
    this._emitterMax = this._emitterMin + duration

    if (this.scrollMotionEmitter) {
      this.scrollMotionEmitter.min = this._emitterMin
      this.scrollMotionEmitter.max = this._emitterMax
    }
  }

  _memoizeMetrics () {
    this.windowHeight = viewportEmitter.clientHeight()
    this.elHeight = getDimensions(this.el).height
    this.elTop = getPagePosition(this.el).top
  }

  _setupClipPath () {
    if (!this.hasClipPath) {
      return
    }
    if (!canUseClipPath) {
      this.clipElements = [null, null, null, null]
      for (let i = 0, l = 4; i < l; i++) { // Iterate over each side
        for (let point in this.options.clipPath) { // Iterate over each keyframe
          let keyframe = this.options.clipPath[point]
          if (keyframe[i] > 0) {
            let ele = this.clipElements[i] = document.createElement('div')
            ele.classList.add('cmt-clip-' + i)
            ele.style.backgroundColor = this.options.clipPathColor
            this.el.appendChild(ele)
            break // Break out of keyframe loop
          }
        }
      }
    }
  }

  _initScrollMotionEmitter () {
    if (this.options.scrollMotionEmitter) {
      this.scrollMotionEmitter = this.options.scrollMotionEmitter
    } else if (this.options.elementScroll) {
      this.scrollMotionEmitter = new ElementScrollMotionEmitter(this.el, {
        smooth: this.options.smooth,
        overrideScroll: this.options.overrideScroll,
        overrideResize: this.options.overrideResize,
        offsetTop: this.options.offsetTop,
        offsetBottom: this.options.offsetBottom,
        friction: this.options.friction
      })
    } else {
      this.scrollMotionEmitter = new ScrollMotionEmitter({
        smooth: this.options.smooth,
        overrideScroll: this.options.overrideScroll,
        min: this._emitterMin,
        max: this._emitterMax,
        friction: this.options.friction
      })
    }
    if (!this.scrollMotionEmitter.isRunning()) {
      this.scrollMotionEmitter.start()
    }
  }

  _setupEvents () {
    this.scrollMotionEmitter.on('draw', this._update)
  }

  _teardownEvents () {
    this.scrollMotionEmitter.off('draw', this._update)
  }

  _getKeyframeData (keyframes) {
    var keys = Object.keys(keyframes)
    if (keys.length === 1) {
      return [this._progress, keyframes[keys[0]], keyframes[keys[0]]]
    }
    var startPoint = null
    var endPoint = null
    for (let point in keyframes) {
      var f = parseFloat(point)
      if ((startPoint === null || f > startPoint) && f <= this._progress) {
        startPoint = point
      }
      if ((endPoint === null || f < endPoint) && f >= this._progress) {
        endPoint = point
      }
    }
    // If start keyframe wasn't found, set it to the first keyframe
    if (startPoint === null) {
      startPoint = keys[0]
    }
    // If end keyframe wasn't found, set it to the last keyframe
    if (endPoint === null) {
      endPoint = keys[keys.length - 1]
    }

    var keyframeDuration = endPoint - startPoint || 1
    var keyframeProgress = (this._progress - startPoint) / keyframeDuration
    return [keyframeProgress, keyframes[startPoint], keyframes[endPoint]]
  }

  _setElementTransform () {
    if (!this.hasTransform) {
      return
    }

    // Scale
    let [scaleProgress, scaleStart, scaleEnd] = this._getKeyframeData(this.options.scale)
    this.transforms.scaleX = lerp(scaleProgress, scaleStart[0], scaleEnd[0])
    this.transforms.scaleY = lerp(scaleProgress, scaleStart[1], scaleEnd[1])
    var scale = (this.transforms.scaleX === 1 && this.transforms.scaleY === 1) ? '' : ' scale(' + this.transforms.scaleX + ',' + this.transforms.scaleY + ')'

    // Translate
    let [translateProgress, translateStart, translateEnd] = this._getKeyframeData(this.options.translate)
    this.transforms.translateX = lerp(translateProgress, translateStart[0], translateEnd[0])
    this.transforms.translateY = lerp(translateProgress, translateStart[1], translateEnd[1])
    var translate = ' translate3d(' + this.transforms.translateX + 'px,' + this.transforms.translateY + 'px,0)'

    // Rotate
    this.transforms.rotate = lerp(...this._getKeyframeData(this.options.rotate))
    var rotation = (this.transforms.rotate === 0) ? '' : ' rotate(' + this.transforms.rotate + 'deg)'

    // Apply
    this.el.style[prefixedTransform] = scale + translate + rotation
  }

  _setElementClipPath () {
    if (!this.hasClipPath) {
      return
    }
    let [progress, start, end] = this._getKeyframeData(this.options.clipPath)
    if (canUseClipPath) {
      let vals = []
      for (let i = 0, l = 4; i < l; i++) {
        vals[i] = lerp(progress, start[i], end[i])
      }
      this.el.style.clipPath = `inset(${vals[0]}px ${vals[1]}px ${vals[2]}px ${vals[3]}px)`
    } else {
      for (let i = 0, l = 4; i < l; i++) {
        let clipElement = this.clipElements[i]
        if (clipElement) {
          let attr = 'height'
          if (i % 2) {
            attr = 'width'
          }
          clipElement.style[attr] = lerp(progress, start[i], end[i]) + 'px'
        }
      }
    }
  }

  _setElementOpacity () {
    if (!this.hasOpacity) {
      return
    }
    this.opacity = lerp(...this._getKeyframeData(this.options.fade))
    this.el.style.opacity = this.opacity
  }

  _setStatus () {
    if ((this._progress > 0 && this._progress < 1) && !this._isAnimating) {
      this._isAnimating = true
      this.el.classList.remove('has-animated')
      this.el.classList.remove('has-not-animated')
      this.el.classList.add('is-animating')
    } else {
      if (this._progress >= 1 && this._isAnimating) {
        this._isAnimating = false
        this.el.classList.remove('is-animating')
        this.el.classList.remove('has-not-animated')
        this.el.classList.add('has-animated')
      } else {
        if (this._progress <= 0 && this._isAnimating) {
          this._isAnimating = false
          this.el.classList.remove('is-animating')
          this.el.classList.remove('has-animated')
          this.el.classList.add('has-not-animated')
        }
      }
    }
  }

  _update (a) {
    if (isNaN(a.progress)) {
      return
    }
    this._progress = a.progress
    this._setElementTransform()
    this._setElementClipPath()
    this._setElementOpacity()
  }
}
