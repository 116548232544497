import { videoJs } from 'instance'

// Initialize uninitialized video blocks whenever a layout is loaded
window.addEventListener('cmpb-layout-loaded', async (event) => {
  let videos = event.layout.querySelectorAll('.cmpb-block-video .video-js')
  if (!videos.length) {
    return
  }

  let videojs = await videoJs()
  if (!videojs) {
    return
  }

  for (let video of [...videos]) {
    let player = videojs(video)
    player.ready(() => {
      if (player.autoplay() && player.paused()) {
        // BUG Autoplay is paused when vjs is initialized in chrome, so we start playing again
        player.play()
      } else {
        // HACK solution for issue https://github.com/videojs/video.js/issues/4193
        if (player.autoplay() && !player.paused() && player.hasClass('vjs-paused')) {
          player.pause()
          player.play()
        }
      }
    })
  }
})
