import viewportEmitter from 'lib/viewport-emitter'
import {page} from 'instance'

// Require all layout scripts
var requireTest = require.context('../layouts', true, /\.js$/)
requireTest.keys().forEach(requireTest)

const {breakpoints} = window.CMTConfig.variables

// Initialize viewport breakpoints
viewportEmitter.setBreakpoints('small', [
  ['medium', `(min-width: ${breakpoints.small}em)`],
  ['large', `(min-width: ${breakpoints.medium}em)`]
])

// Add layouts to the page instance when they're loaded
window.addEventListener('cmpb-layout-loaded', function (event) {
  page.addSection(event.layout)
})

window.cmtInitGoogleMaps = function () {
  let event = new window.CustomEvent('cmt-google-maps-initialize')
  window.dispatchEvent(event)
}
