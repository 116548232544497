export default class EventEmitterMicro {
  constructor () {
    this._events = {}
  }

  on (type, listener) {
    this._events[type] = this._events[type] || []
    this._events[type].unshift(listener)
  }

  once (type, listener) {
    let wrapped = (data) => {
      this.off(type, wrapped)
      if (data !== undefined) {
        listener(data)
      } else {
        listener()
      }
    }
    this.on(type, wrapped)
  }

  off (type, listener) {
    if (!this.has(type)) {
      return
    }
    var index = this._events[type].indexOf(listener)
    if (index === -1) {
      return
    }
    this._events[type].splice(index, 1)
  }

  trigger (type, data) {
    if (!this.has(type)) {
      return
    }
    for (let i = this._events[type].length - 1; i >= 0; i--) {
      if (data !== undefined) {
        this._events[type][i](data)
      } else {
        this._events[type][i]()
      }
    }
  }

  has (type) {
    if (type in this._events === false || this._events[type].length === 0) {
      return false
    }
    return true
  }

  destroy () {
    for (let type in this._events) {
      this._events[type] = null
    }
    this._events = null
  }
}
