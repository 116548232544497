import viewportEmitter from './viewport-emitter'
import EventEmitterMicro from './event-emitter-micro'
import {isElement, isNodeList} from './helpers/dom-nodes'
import {getDimensions, getPagePosition} from './helpers/dom-metrics'
import {clone, extend} from './helpers/object'
import {toArray} from './helpers/array'

const defaultOptions = {
  autoStart: false
}

class TrackedElement extends EventEmitterMicro {
  constructor (element) {
    if (!isElement(element)) {
      throw new TypeError('TrackedElement: ' + element + ' is not a valid DOM element')
    }
    super()
    this.element = element
    this.inView = false
    this.percentInView = 0
    this.pixelsInView = 0
    this.offsetTop = 0
    this.top = 0
    this.right = 0
    this.bottom = 0
    this.left = 0
    this.width = 0
    this.height = 0
  }

  destroy () {
    this.element = null
    super.destroy()
  }
}

export default class ElementTracker extends EventEmitterMicro {
  constructor (element, options) {
    super()
    this.options = clone(defaultOptions)
    this.options = typeof options === 'object' ? extend(this.options, options) : this.options
    this._scrollY = viewportEmitter.scrollY()
    this._windowHeight = viewportEmitter.clientHeight()
    this.tracking = false
    this.elements = []
    if (element && (Array.isArray(element) || isNodeList(element) || isElement(element))) {
      this.addElements(element)
    }
    this.refreshAllElementStates = this.refreshAllElementStates.bind(this)
    this.refreshAllElementMetrics = this.refreshAllElementMetrics.bind(this)
    if (this.options.autoStart) {
      this.start()
    }
  }

  destroy () {
    this.stop()
    for (let i = 0, len = this.elements.length; i < len; i++) {
      this.elements[i].destroy()
    }
    this.elements = null
    this.options = null
  }

  _registerElements (elements) {
    elements = [].concat(elements)
    elements.forEach((element) => {
      if (this._elementInDOM(element)) {
        var trackedElement = new TrackedElement(element)
        trackedElement.offsetTop = trackedElement.element.offsetTop
        this.elements.push(trackedElement)
      }
    })
  }

  _registerTrackedElements (trackedElements) {
    [].concat(trackedElements).forEach((trackedElement) => {
      if (this._elementInDOM(trackedElement.element)) {
        trackedElement.offsetTop = trackedElement.element.offsetTop
        this.elements.push(trackedElement)
      }
    })
  }

  _elementInDOM (element) {
    let body = document.getElementsByTagName('body')[0]
    return isElement(element) && body.contains(element)
  }

  _elementPercentInView (trackedElement) {
    return trackedElement.pixelsInView / trackedElement.height
  }

  _elementPixelsInView (trackedElement) {
    let a = trackedElement.top - this._scrollY
    let b = trackedElement.bottom - this._scrollY
    if (a > this._windowHeight || b < 0) {
      return 0
    }
    return Math.min(b, this._windowHeight) - Math.max(a, 0)
  }

  _ifInView (trackedElement, alreadyInView) {
    if (!alreadyInView) {
      trackedElement.trigger('enterview', trackedElement)
    }
  }

  _ifAlreadyInView (trackedElement) {
    if (!trackedElement.inView) {
      trackedElement.trigger('exitview', trackedElement)
    }
  }

  addElements (elements) {
    elements = isNodeList(elements) ? toArray(elements) : [].concat(elements)
    elements.forEach(this.addElement, this)
  }

  addElement (element) {
    let trackedElement = null
    if (isElement(element)) {
      trackedElement = new TrackedElement(element)
      this._registerTrackedElements(trackedElement)
      this.refreshElementMetrics(trackedElement)
      this.refreshElementState(trackedElement)
    } else {
      throw new TypeError(`ElementTracker: ${element} is not a valid DOM element`)
    }
    return trackedElement
  }

  removeElement (element) {
    this.elements = this.elements.filter((trackedElement) => {
      return !(trackedElement === element || trackedElement.element === element)
    })
  }

  start () {
    if (this.tracking === false) {
      this.tracking = true
      viewportEmitter.on('resize', this.refreshAllElementMetrics)
      viewportEmitter.on('orientationchange', this.refreshAllElementMetrics)
      viewportEmitter.on('scroll', this.refreshAllElementStates)
      this.refreshAllElementMetrics()
    }
  }

  stop () {
    if (this.tracking === true) {
      this.tracking = false
      viewportEmitter.off('resize', this.refreshAllElementMetrics)
      viewportEmitter.off('orientationchange', this.refreshAllElementMetrics)
      viewportEmitter.off('scroll', this.refreshAllElementStates)
    }
  }

  refreshAllElementMetrics () {
    this._scrollY = viewportEmitter.scrollY()
    this._windowHeight = viewportEmitter.clientHeight()
    this.elements.forEach(this.refreshElementMetrics, this)
  }

  refreshElementMetrics (trackedElement) {
    let dimensions = getDimensions(trackedElement.element)
    let position = getPagePosition(trackedElement.element)
    trackedElement = extend(trackedElement, dimensions, position)
    return this.refreshElementState(trackedElement)
  }

  refreshAllElementStates () {
    this._scrollY = viewportEmitter.scrollY()
    this.elements.forEach(this.refreshElementState, this)
  }

  refreshElementState (trackedElement) {
    let alreadyInView = trackedElement.inView
    trackedElement.pixelsInView = this._elementPixelsInView(trackedElement)
    trackedElement.percentInView = this._elementPercentInView(trackedElement)
    trackedElement.inView = trackedElement.pixelsInView > 0
    if (trackedElement.inView) {
      this._ifInView(trackedElement, alreadyInView)
    }
    if (alreadyInView) {
      this._ifAlreadyInView(trackedElement)
    }
    return trackedElement
  }
}
