export const COMMENT_NODE = 8
export const DOCUMENT_FRAGMENT_NODE = 11
export const DOCUMENT_NODE = 9
export const DOCUMENT_TYPE_NODE = 10
export const ELEMENT_NODE = 1
export const TEXT_NODE = 3

export function isNodeType (node, types) {
  if (!isNode(node)) {
    return false
  }
  if (typeof types === 'number') {
    return (node.nodeType === types)
  }
  return (types.indexOf(node.nodeType) !== -1)
}

export function isComment (node) {
  return isNodeType(node, COMMENT_NODE)
}

export function isDocument (node) {
  return isNodeType(node, DOCUMENT_NODE)
}

export function isDocumentFragment (node) {
  return isNodeType(node, DOCUMENT_FRAGMENT_NODE)
}

export function isDocumentType (node) {
  return isNodeType(node, DOCUMENT_TYPE_NODE)
}

export function isElement (node) {
  return isNodeType(node, ELEMENT_NODE)
}

export function isNode (node) {
  return !!(node && node.nodeType)
}

const isNodeRegex = /^\[object (HTMLCollection|NodeList|Object)\]$/
export function isNodeList (list) {
  if (!list) {
    return false
  }
  if (typeof list.length !== 'number') {
    return false
  }
  if (typeof list[0] === 'object' && (!list[0] || !list[0].nodeType)) {
    return false
  }
  return isNodeRegex.test(list.toString())
};

export function isTextNode (node) {
  return isNodeType(node, TEXT_NODE)
}
