import * as componentMap from './components/'
import viewportEmitter from './viewport-emitter'
import EventEmitterMicro from './event-emitter-micro'
import ElementTracker from './element-tracker'
import ElementEngagement from './element-engagement'
import { getPagePosition } from './helpers/dom-metrics'

class Section extends EventEmitterMicro {
  constructor (layout, trackedElement, currentBreakpoint, scrollPosition, windowHeight, index) {
    if (arguments.length !== 6) {
      throw new Error('Incorrect number of arguments passed to Section')
    }
    super()
    this.element = layout
    this.trackedElement = trackedElement
    this.elementEngagement = new ElementEngagement(null, {
      autoStart: false
    })

    this.rafWhenVisible = this.rafWhenVisible || false
    this.index = index
    this.isVisible = this.trackedElement.pixelsInView > 0
    this.hasAnimatedIn = false
    this.isActive = false
    this.cachedBreakpoint = currentBreakpoint
    this.cachedScrollPosition = scrollPosition
    this.cachedWindowHeight = windowHeight
    this.scrollToPosition = 0
    this.updateScrollToPosition()
    this._components = []
    this.setupComponents(currentBreakpoint, scrollPosition, windowHeight)
  }

  setupComponents () {
    var componentElements = this.element.querySelectorAll('[data-components]')
    componentElements = Array(...componentElements)
    if (this.element.hasAttribute('data-components')) {
      componentElements.push(this.element)
    }
    for (let f = 0; f < componentElements.length; f++) {
      let componentElement = componentElements[f]
      let componentList = componentElement.dataset.components
      componentList = componentList.split(' ')
      for (let i = 0, l = componentList.length; i < l; i++) {
        let component = componentList[i]
        if (component === '' || component === ' ') {
          continue
        }
        this.addComponentOfType(component, componentElement)
      }
      setTimeout(this.elementEngagement.refreshAllElementStates.bind(this.elementEngagement), 100)
    }
  }

  addComponentOfType (componentName, element) {
    if (!(componentName in componentMap)) {
      throw new Error(`Section::setupComponents parsing '#${element.id}.${element.className}' no component type '${componentName}' found!`)
    }
    var ComponentClass = componentMap[componentName]
    if (!this.componentIsSupported(ComponentClass, componentName)) {
      console.log(`Section::setupComponents unsupported component '${componentName}'. Reason: '${componentName}.IS_SUPPORTED' returned false`)
      return
    }
    var component = new ComponentClass(this, element, componentName, this.cachedBreakpoint, this.cachedScrollPosition, this.cachedWindowHeight, this._components.length)
    this.rafWhenVisible = component.rafWhenVisible || this.rafWhenVisible
    this._components.push(component)
  };

  componentIsSupported (component, componentName) {
    var isSupported = component.IS_SUPPORTED
    if (isSupported === undefined || typeof isSupported !== 'function') {
      return true
    }
    return component.IS_SUPPORTED() === true
  }

  updateScrollToPosition () {
    return (this.scrollToPosition = getPagePosition(this.element).top)
  }

  setupEvents () {
    for (let i = 0, l = this._components.length; i < l; i++) {
      this._components[i].setupEvents()
    }
  }

  teardownEvents () {
    for (let i = 0, l = this._components.length; i < l; i++) {
      this._components[i].teardownEvents()
    }
  }

  destroy () {
    this.teardownEvents()
    this.elementEngagement.stop()
    this.elementEngagement = null
    for (let i = 0, l = this._components.length; i < l; i++) {
      this._components[i].destroy()
    }
    this._components = null
    this.trackedElement = null
    this.element = null
    super.destroy()
  }

  activate () {
    this.element.classList.add('active')
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].activate()
    }
    this.isActive = true
    if (!this.hasAnimatedIn) {
      this.element.classList.add('animated')
      this.animateIn()
      this.hasAnimatedIn = true
    }
  }

  deactivate () {
    this.element.classList.remove('active')
    this.isActive = false
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].deactivate()
    }
  }

  animateIn () {
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].animateIn()
    }
  }

  onRequestAnimationFrame () {
    for (let i = 0, l = this._components.length; i < l; i++) {
      let component = this._components[i]
      if (!component.isEnabled) {
        continue
      }
      if (component.rafWhenVisible || this.isActive) {
        component.onRequestAnimationFrame()
      }
    }
  }

  onResizeImmediate (event, scrollPosition, windowHeight) {
    this.cachedScrollPosition = scrollPosition
    this.cachedWindowHeight = windowHeight
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].onResizeImmediate(event, scrollPosition, windowHeight)
    }
  }

  onOrientationChange (event, orientation, scrollPosition, windowHeight) {
    this.cachedScrollPosition = scrollPosition
    this.cachedWindowHeight = windowHeight
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].onOrientationChange(event, orientation, scrollPosition, windowHeight)
    }
  }

  onResizeDebounced (event, scrollPosition, windowHeight) {
    this.updateScrollToPosition()
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].onResizeDebounced(event, scrollPosition, windowHeight)
    }
    this.elementEngagement.refreshAllElementMetrics()
  }

  onScroll (event, scrollPosition, windowHeight) {
    this.cachedScrollPosition = scrollPosition
    this.elementEngagement.refreshAllElementStates()
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].onScroll(event, scrollPosition, windowHeight)
    }
  }

  onSectionWillAppear (scrollPosition, windowHeight) {
    this.cachedScrollPosition = scrollPosition
    this.isVisible = true
    this.elementEngagement.refreshAllElementStates()
    for (let i = 0, l = this._components.length; i < l; i++) {
      this._components[i].onSectionWillAppear(scrollPosition, windowHeight)
    }
  }

  onSectionWillDisappear (scrollPosition, windowHeight) {
    this.cachedScrollPosition = scrollPosition
    this.isVisible = false
    for (let i = 0, l = this._components.length; i < l; i++) {
      this._components[i].onSectionWillDisappear(scrollPosition, windowHeight)
    }
  }

  onBreakpoint (to, from, scrollPosition, windowHeight) {
    this.cachedBreakpoint = to
    for (let i = 0, l = this._components.length; i < l; i++) {
      if (!this._components[i].isEnabled) {
        continue
      }
      this._components[i].onBreakpoint(to, from, scrollPosition, windowHeight)
    }
    this.elementEngagement.refreshAllElementMetrics()
  }
}

export default class Page {
  constructor () {
    this._sections = []
    this._visibleSections = []
    this._elementTracker = new ElementTracker(null, {
      autoStart: true
    })
    this._currentSection = null
    this._currentBreakpoint = viewportEmitter.breakpoint
    this._cachedScrollY = this._getScrollY(true)
    this._cachedWindowHeight = this._getWindowHeight(true)
    this._rafId = -1
    this._resizeTimeout = -1
    this._resizeTimeoutDelay = this._resizeTimeoutDelay || 250
    this.setupEvents()
    this._updateSectionVisibility(this._getScrollY(), this._getWindowHeight())
    this._onRequestAnimationFrame()
  }

  destroy () {
    for (let a = 0, b = this._sections.length; a < b; a++) {
      this._sections[a].destroy()
    }
    this.teardownEvents()
    this._elementTracker.destroy()
    this._elementTracker = null
    this._sections = null
    this._currentSection = null
    this._visibleSections = null
  }

  setupEvents () {
    this._onScroll = this._onScroll.bind(this)
    this._onBreakpoint = this._onBreakpoint.bind(this)
    this._onPageDidAppear = this._onPageDidAppear.bind(this)
    this._onResizeImmediate = this._onResizeImmediate.bind(this)
    this._onOrientationChange = this._onOrientationChange.bind(this)
    this._onPageWillDisappear = this._onPageWillDisappear.bind(this)
    this._onRequestAnimationFrame = this._onRequestAnimationFrame.bind(this)
    this.performDeepMetricsRefresh = this.performDeepMetricsRefresh.bind(this)
    viewportEmitter.on('scroll', this._onScroll)
    viewportEmitter.on('resize', this._onResizeImmediate)
    viewportEmitter.on('orientationchange', this._onOrientationChange)
    viewportEmitter.on('breakpoint', this._onBreakpoint)
    // TODO
    // Page.on(Page.DEEP_REFRESH_ALL_METRICS, this.performDeepMetricsRefresh)
  }

  teardownEvents () {
    viewportEmitter.off('scroll', this._onScroll)
    viewportEmitter.off('resize', this._onResizeImmediate)
    viewportEmitter.off('orientationchange', this._onOrientationChange)
    viewportEmitter.off('breakpoint', this._onBreakpoint)
    // TODO
    // Page.off(Page.DEEP_REFRESH_ALL_METRICS, this.performDeepMetricsRefresh);
    this._elementTracker.stop()
    window.clearTimeout(this._resizeTimeout)
    window.cancelAnimationFrame(this._rafId)
  }

  addSection (layout) {
    for (let i = 0, l = this._sections.length; i < l; i++) {
      if (this._sections[i].element === layout) {
        throw new Error(`Page::addSection tried to add a section that is already present`)
      }
    }
    var scrollPosition = this._getScrollY()
    var currentBreakpoint = this._getCurrentBreakpoint()
    var windowHeight = this._getWindowHeight()

    var trackedElement = this._elementTracker.addElement(layout)
    this._elementTracker.refreshElementState(trackedElement)
    var section = new Section(layout, trackedElement, currentBreakpoint, scrollPosition, windowHeight, this._sections.length)
    section.setupEvents()
    this._sections.push(section)

    this._updateSectionVisibility(scrollPosition, windowHeight)
    this._onRequestAnimationFrame()
  }

  _activateSection (section) {
    if (this._currentSection === section) {
      return
    }
    if (this._currentSection) {
      this._currentSection.deactivate()
    }
    this._currentSection = section
    if (section) {
      this._currentSection.activate()
    }
  }

  _updateSectionVisibility (scrollPosition, windowHeight) {
    var currentSection = this._sections[0]
    var visibleSections = []
    var highestPixelCount = 0
    for (let i = 0, l = this._sections.length; i < l; i++) {
      let section = this._sections[i]
      let pixelsInView = section.trackedElement.pixelsInView
      if (pixelsInView > highestPixelCount) {
        currentSection = section
        highestPixelCount = pixelsInView
      }
      if (pixelsInView > 0.000001) {
        visibleSections.push(section)
      }
    }
    for (let i = 0, l = Math.max(this._visibleSections.length, visibleSections.length); i < l; i++) {
      if (this._visibleSections[i] && visibleSections.indexOf(this._visibleSections[i]) === -1) {
        this._visibleSections[i].onSectionWillDisappear(scrollPosition, windowHeight)
      }
      if (visibleSections[i] && this._visibleSections.indexOf(visibleSections[i]) === -1) {
        visibleSections[i].onSectionWillAppear(scrollPosition, windowHeight)
      }
    }
    this._visibleSections = visibleSections
    this._activateSection(currentSection)
  }

  _onPageDidAppear (event) {};
  _onPageWillDisappear (event) {
    this.destroy()
  }

  _onBreakpoint (event) {
    var to = event.to
    var from = event.from
    this._currentBreakpoint = to
    var scrollPosition = this._getScrollY()
    var windowHeight = this._getWindowHeight()
    this._elementTracker.refreshAllElementMetrics()
    for (let i = 0, l = this._sections.length; i < l; i++) {
      this._sections[i].onBreakpoint(to, from, scrollPosition, windowHeight)
    }
  }

  _onScroll (event) {
    var scrollPosition = this._getScrollY(true)
    var windowHeight = this._getWindowHeight()
    this._updateSectionVisibility(scrollPosition, windowHeight)
    for (let i = 0, l = this._visibleSections.length; i < l; i++) {
      this._visibleSections[i].onScroll(event, scrollPosition, windowHeight)
    }
  }

  _onResizeDebounced (event) {
    var scrollPosition = this._getScrollY()
    var windowHeight = this._getWindowHeight()
    for (let i = 0, l = this._sections.length; i < l; i++) {
      this._sections[i].onResizeDebounced(event, scrollPosition, windowHeight)
    }
    this._updateSectionVisibility(scrollPosition, windowHeight)
  }

  performDeepMetricsRefresh () {
    this._elementTracker.refreshAllElementMetrics()
    for (let i = 0, l = this._sections.length; i < l; i++) {
      this._sections[i].elementEngagement.refreshAllElementMetrics()
      this._sections[i].updateScrollToPosition()
    }
    this._updateSectionVisibility(this._getScrollY(), this._getWindowHeight())
  }

  _onOrientationChange (event) {
    var scrollPosition = this._getScrollY(true)
    var windowHeight = this._getWindowHeight(true)
    var orientation = event.orientation
    for (let i = 0, l = this._sections.length; i < l; i++) {
      this._sections[i].onOrientationChange(event, orientation, scrollPosition, windowHeight)
    }
  }

  _onResizeImmediate (event) {
    var scrollPosition = this._getScrollY()
    var windowHeight = this._getWindowHeight(true)
    for (let i = 0, l = this._sections.length; i < l; i++) {
      this._sections[i].onResizeImmediate(event, scrollPosition, windowHeight)
    }
    window.clearTimeout(this._resizeTimeout)
    this._resizeTimeout = window.setTimeout(this._onResizeDebounced.bind(this, event), this._resizeTimeoutDelay)
  }

  _onRequestAnimationFrame () {
    this._rafId = window.requestAnimationFrame(this._onRequestAnimationFrame)
    for (let i = 0, l = this._visibleSections.length; i < l; i++) {
      let sections = this._visibleSections[i]
      if (sections.rafWhenVisible || sections.isActive) {
        sections.onRequestAnimationFrame()
      }
    }
  }

  _getScrollY (update) {
    if (update) {
      this._cachedScrollY = window.pageYOffset || (document.documentElement || document.body).scrollTop
    }
    return this._cachedScrollY
  }

  _getWindowHeight (update) {
    if (update) {
      this._cachedWindowHeight = document.documentElement.clientHeight || window.innerHeight
    }
    return this._cachedWindowHeight
  }

  _getVisibleBottomOfPage () {
    return this._getScrollY() + this._getWindowHeight()
  }

  _getCurrentBreakpoint () {
    return this._currentBreakpoint
  }
}
